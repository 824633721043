<script setup lang="ts">
import { format, formatDistance, parseISO } from 'date-fns';
import { computed } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import BaseBadge from '@/components/base-badge.vue';
import { toCurrency } from '@/utils/filters';

type KycInReviewStatuses = 'pending' | 'rejected' | 'approved'

const CREATED_AT_AASM_STATES = ['draft', 'inviting'];
const SUBMITTED_AT_AASM_STATES = ['application_in_review', 'in_kyc', 'kyc_in_review', 'contracts_signature_pending'];
const MEETING_AT_AASM_STATES = ['contracts_signed', 'disbursed'];
const KYC_IN_REVIEW_STATUS_CLASSES: Record<KycInReviewStatuses, string> = {
  pending: '',
  rejected: '!bg-red-100',
  approved: '!bg-green-100',
};

interface Props {
  groupLoanApplication: GroupLoanApplication;
  displayStatusBadge?: boolean;
}

const props = defineProps<Props>();

const fieldText = computed(() => {
  if (CREATED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return 'Creada';
  } else if (SUBMITTED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return 'Enviada';
  } else if (MEETING_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return 'Reunidos';
  }

  return 'Actualizada';
});
const dateToDisplay = computed(() => {
  if (CREATED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.createdAt;
  } else if (SUBMITTED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.submittedAt;
  } else if (MEETING_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.meetingAt;
  }

  return props.groupLoanApplication.updatedAt;
});
const formattedDate = computed(() => (
  dateToDisplay.value ? `(${format(parseISO(dateToDisplay.value), 'dd-MM-yyyy')})` : ''
));
const formattedTimeAgo = computed(() => (
  dateToDisplay.value ? formatDistance(parseISO(dateToDisplay.value), new Date(), { addSuffix: true }) : ''),
);
const overallKycStatus = computed<KycInReviewStatuses | null>(() => {
  if (props.groupLoanApplication.aasmState !== 'kyc_in_review') {
    return null;
  }

  const kycSubmittedGroupMemberLoanApplications = props.groupLoanApplication.memberLoanApplications
    .filter((memberLoanApplication) => memberLoanApplication.aasmState === 'kyc_submitted');
  const anyPendingReview = kycSubmittedGroupMemberLoanApplications
    .some((memberLoanApplication) => memberLoanApplication.kycReviewStatus === 'pending_review');

  if (anyPendingReview) {
    return 'pending';
  }

  const anyRejectedReview = kycSubmittedGroupMemberLoanApplications.some(
    (memberLoanApplication) => memberLoanApplication.kycReviewStatus === 'rejected',
  );

  if (anyRejectedReview) {
    return 'rejected';
  }

  return 'approved';
});

const PRE_REVIEW_STATES = ['inviting', 'draft', 'application_in_review', 'application_rejected'];
const amountToDisplay = computed(() => {
  if (PRE_REVIEW_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.totalRequestedAmount;
  }

  return props.groupLoanApplication.totalAcceptedAmount;
});

const memberCountToDisplay = computed(() => {
  if (['inviting', 'draft'].includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.group.membersAndInvitationsCount;
  }

  return props.groupLoanApplication.memberLoanApplications.filter(
    (memberLoanApplication) => memberLoanApplication.aasmState !== 'rejected',
  ).length;
});
</script>

<template>
  <a
    class="relative flex w-64 cursor-pointer flex-col gap-y-4 rounded-md bg-white p-2 hover:bg-gray-200"
    :href="`/reviews/group_loan_applications/${groupLoanApplication.id}`"
    :class="overallKycStatus && KYC_IN_REVIEW_STATUS_CLASSES[overallKycStatus]"
  >
    <div class="flex justify-between">
      <div class="flex items-center gap-x-2">
        <div
          v-if="overallKycStatus === 'pending'"
          class="absolute right-0 top-0 flex size-2 items-center"
        >
          <div class="absolute size-3 animate-ping rounded-full bg-primary-400 opacity-75" />
          <div class="absolute size-3 rounded-full bg-primary-500" />
        </div>

        <h4 class="font-semibold">
          {{ groupLoanApplication.group.name }}
        </h4>
      </div>
      <base-badge
        v-if="groupLoanApplication.cycle > 1"
        color="blue"
        label="Renovación"
        size="xs"
      />
    </div>
    <p class="text-xs text-gray-400">
      {{ fieldText }} {{ formattedTimeAgo }} {{ formattedDate }}
    </p>
    <div className="flex gap-x-4 text-xs text-gray-600">
      <p
        v-if="groupLoanApplication.group.promoter"
        class="text-black"
      >
        {{ groupLoanApplication.group.promoter.firstName }} {{ groupLoanApplication.group.promoter.paternalSurname }}
      </p>
      <p>💸 {{ toCurrency(amountToDisplay) }}</p>
      <p>👥 {{ memberCountToDisplay }}</p>
    </div>
  </a>
</template>
