import { format as originalFormat, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import humps from 'humps';

function toCurrency(value: number, options = {}): string | number {
  if (!Number(value) && value !== 0) {
    return value;
  }

  const defaultOptions = {
    symbol: '$',
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: Number.isInteger(value) ? 0 : 2, // eslint-disable-line no-magic-numbers
  };

  const settings = { ...defaultOptions, ...options };
  const {
    symbol, decimalSeparator, thousandSeparator, precision,
  } = settings;

  const numericValue = Number(value).toFixed(precision);

  // eslint-disable-next-line prefer-const
  let [integerPart, decimalPart] = numericValue.split('.');

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  const formattedValue = decimalPart ?
    `${integerPart}${decimalSeparator}${decimalPart}` :
    integerPart;

  return `${symbol}${formattedValue}`;
}

function formatNumber(
  value: number,
  options: Partial<Intl.NumberFormatOptions> & { separator?: string } = {},
): string {
  const { separator = ',', minimumFractionDigits = 0 } = options;

  if (!Number(value) && value !== 0) {
    return value.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits, ...options });

  return formatter.format(value).replaceAll(',', separator);
}

function abbreviateNumber(value: number): string {
  if (!Number(value) && value !== 0) {
    return value.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });

  return formatter.format(value);
}

function formatDate(dateString: string, formatString: string) {
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a new Date object - remember to subtract 1 from month as JS months start at 0
  const date = new Date(year, month - 1, day);

  return originalFormat(date, formatString);
}

function formatTime(time: string, formatString: string) {
  return originalFormat(new Date(time), formatString);
}

function formatRelativeDate(time: string, strict: boolean = false) {
  // eslint-disable-next-line no-magic-numbers
  const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000; // milliseconds in one week
  const date = new Date(time);

  const now = new Date();
  if (now.getTime() - date.getTime() < ONE_WEEK_IN_MS) {
    return strict ? formatDistanceToNowStrict(date, { addSuffix: true }) : formatDistanceToNow(date, { addSuffix: true });
  }

  return originalFormat(date, 'dd-MM-yyyy');
}

function camelizeKeys(object: object): object {
  return humps.camelizeKeys(object);
}

function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function normalize(str: string): string {
  return removeAccents(str.replace(/\s+/g, '').toLowerCase()).normalize('NFKC');
}

export {
  toCurrency,
  formatNumber,
  abbreviateNumber,
  formatDate,
  formatTime,
  formatRelativeDate,
  camelizeKeys,
  removeAccents,
  normalize,
};
