<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { UserGroupIcon } from '@heroicons/vue/24/outline';
import { format, formatDistance, parseISO } from 'date-fns';
import { ref, computed } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import { useUpdatePromoterPresidentInvite } from '@/api/promoterPresidentInvite/mutations';
import { useFetchPromoterPresidentInvites } from '@/api/promoterPresidentInvite/queries';
import BaseBackButton from '@/components/base-back-button.vue';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseEmptyState from '@/components/base-empty-state.vue';
import GroupPromoterInputFormModal from '@/components/promoters/group-promoter-input-form-modal.vue';
import useUIStore from '@/stores/ui';
import { toCurrency } from '@/utils/filters';

interface Props {
  groupLoanApplications?: GroupLoanApplication[];
}

defineProps<Props>();

const uiStore = useUIStore();

function formattedSubmittedAt(stringDate: string) {
  const formattedDate = format(parseISO(stringDate), 'dd-MM-yyyy');
  const timeAgo = formatDistance(parseISO(stringDate), new Date(), { addSuffix: true });

  return `${formattedDate} (${timeAgo})`;
}

function acceptedAmount(groupMemberLoanApplication: GroupMemberLoanApplication) {
  if (['draft', 'submitted', 'rejected'].includes(groupMemberLoanApplication.aasmState)) return 'En revisión';
  if (!groupMemberLoanApplication.preApproved) return 'En revisión';
  if (!groupMemberLoanApplication.acceptedAmount) return 'En revisión';

  return toCurrency(groupMemberLoanApplication.acceptedAmount);
}

function costPerThousand(groupMemberLoanApplication: GroupMemberLoanApplication) {
  if (['draft', 'submitted', 'rejected'].includes(groupMemberLoanApplication.aasmState)) return 'En revisión';
  if (!groupMemberLoanApplication.preApproved) return 'En revisión';
  if (!groupMemberLoanApplication.costPerThousand) return 'En revisión';

  return toCurrency(groupMemberLoanApplication.costPerThousand);
}

function totalAcceptedAmount(groupLoanApplication: GroupLoanApplication) {
  const forbiddenStates = ['inviting', 'draft', 'application_in_review', 'application_rejected', 'canceled'];

  if (forbiddenStates.includes(groupLoanApplication.aasmState)) {
    return 'En revisión';
  }

  if (!groupLoanApplication.totalAcceptedAmount) return 'En revisión';

  return toCurrency(groupLoanApplication.totalAcceptedAmount);
}

type BadgeColors = 'yellow' | 'blue' | 'green' | 'pink' | 'gray' | 'red';

function getGroupBadgeColor(application: GroupLoanApplication) {
  const colors: Record<GroupLoanApplication['aasmState'], BadgeColors> = {
    'inviting': 'yellow',
    'draft': 'blue',
    'application_in_review': 'green',
    'in_kyc': 'pink',
    'kyc_in_review': 'pink',
    'contracts_signature_pending': 'pink',
    'contracts_signed': 'green',
    'disbursed': 'green',
    'application_rejected': 'gray',
    'canceled': 'gray',
  };

  return colors[application.aasmState];
}

function getMemberBadgeColor(groupMemberLoanApplication: GroupMemberLoanApplication) {
  const colors: Record<GroupMemberLoanApplication['aasmState'], BadgeColors> = {
    'draft': 'yellow',
    'submitted': 'blue',
    'rejected': 'red',
    'in_kyc': 'gray',
    'kyc_submitted': 'gray',
    'contract_signature_pending': 'gray',
    'contract_signed': 'gray',
  };

  return colors[groupMemberLoanApplication.aasmState];
}

const queryParams = new URLSearchParams(window.location.search);
const showingDisbursed = computed(() => queryParams.get('aasm_state') === 'disbursed');
const selectedGroupPromoterInputInviteeId = ref<number | undefined>(undefined);
const showGroupPromoterInputForm = ref(false);

const fetchPromoterPresidentInvitesQ = useFetchPromoterPresidentInvites();
function getPromoterPresidentInvite(groupId: number) {
  return (fetchPromoterPresidentInvitesQ.data || []).find(invite => invite.groupId === groupId);
}

const promoterPresidentInviteId = computed(() => fetchPromoterPresidentInvitesQ.data?.[0]?.id);

const updatePromoterPresidentInputMutation = useUpdatePromoterPresidentInvite({
  id: promoterPresidentInviteId,
  inviteeId: selectedGroupPromoterInputInviteeId,
  onSuccess: () => {
    uiStore.toast({
      message: 'Información actualizada',
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la información',
      type: 'error',
      position: 'top',
    });
  },
});

function handleOpenGroupPromoterForm(groupLoanApplication: GroupLoanApplication) {
  const inviteeId = groupLoanApplication.memberLoanApplications.find(
    (mla) => mla.groupMember.president,
  )?.groupMember?.userId;

  selectedGroupPromoterInputInviteeId.value = inviteeId;
  showGroupPromoterInputForm.value = true;
}

function handleModifyGroupPromoter(values: PromoterPresidentInvite) {
  updatePromoterPresidentInputMutation.mutate(values);
  showGroupPromoterInputForm.value = false;
}

const displayDisbursedUrl = computed(() => {
  const baseUrl = window.location.pathname;

  return showingDisbursed.value ? baseUrl : `${baseUrl}?aasm_state=disbursed`;
});

</script>

<template>
  <div class="flex w-full max-w-md flex-col gap-y-4">
    <base-back-button
      href="/promoters/home"
    />
    <h1 class="text-lg font-bold text-gray-600">
      Solicitudes pendientes
    </h1>
    <a
      class="text-primary-700"
      :href="displayDisbursedUrl"
    >
      {{ showingDisbursed ? 'Mostrar solicitudes pendientes' : 'Mostrar solicitudes de grupos desembolsados' }}
    </a>
    <ul
      v-if="!!groupLoanApplications?.length"
      class="mt-8 flex w-full flex-col gap-y-8"
    >
      <li
        v-for="groupLoanApplication in groupLoanApplications"
        :key="groupLoanApplication.id"
        class="rounded-lg bg-white"
      >
        <Disclosure v-slot="{ open }">
          <DisclosureButton class="flex w-full justify-between gap-x-4 rounded-md px-4 py-2 text-left text-sm">
            <div class="flex flex-row items-center gap-x-2">
              <h2 class="text-sm sm:text-base">
                {{ groupLoanApplication.group.name }}
              </h2>
              <base-badge
                :color="getGroupBadgeColor(groupLoanApplication)"
                :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
                size="xs"
              />
            </div>
            <ChevronUpIcon
              :class="open ? 'rotate-180 transform' : ''"
              class="size-5 text-purple-500"
            />
          </DisclosureButton>
          <DisclosurePanel>
            <div class="flex flex-col gap-y-4 px-4 py-2">
              <base-button
                v-tooltip="getPromoterPresidentInvite(groupLoanApplication.groupId)
                  ? ''
                  : 'El grupo no tiene una invitación creada'"
                variant="white"
                size="sm"
                :disabled="!getPromoterPresidentInvite(groupLoanApplication.groupId)"
                @click="() => handleOpenGroupPromoterForm(groupLoanApplication)"
              >
                Modificar información del grupo
              </base-button>
              <div class="flex flex-col gap-y-4 rounded-md bg-white px-4 py-2">
                <span
                  v-if="groupLoanApplication.submittedAt"
                  class="mt-2 text-xs text-gray-600"
                >
                  Enviada el {{ formattedSubmittedAt(groupLoanApplication.submittedAt) }}
                </span>
                <div class="grid grid-cols-2 gap-x-4">
                  <span class="text-xs">
                    💰 Monto solicitado
                  </span>
                  <span class="text-xs">
                    {{ $filters.toCurrency(groupLoanApplication.totalRequestedAmount) }}
                  </span>
                </div>
                <div
                  class="grid grid-cols-2 gap-x-4"
                >
                  <span class="text-xs">
                    💰 Monto aceptado
                  </span>
                  <span class="text-xs">
                    {{ totalAcceptedAmount(groupLoanApplication) }}
                  </span>
                </div>
                <div class="grid grid-cols-2 gap-x-4">
                  <div class="inline-flex items-center gap-x-1 text-xs">
                    <UserGroupIcon class="size-3.5 shrink-0" />
                    <span>
                      Integrantes
                    </span>
                  </div>
                  <span class="text-xs">
                    {{ groupLoanApplication.memberLoanApplications.length }}
                  </span>
                </div>
              </div>
              <div class="mt-4 flex flex-col gap-y-6">
                <ul
                  v-for="groupMemberLoanApplication in groupLoanApplication.memberLoanApplications"
                  :key="groupMemberLoanApplication.id"
                  class="flex flex-col gap-y-1 text-xs text-gray-700"
                >
                  <span class="font-semibold text-gray-600">
                    {{ groupMemberLoanApplication.groupMember.user.fullName }}
                  </span>
                  <li class="grid grid-cols-2 items-center gap-x-4">
                    <span>
                      Estado
                    </span>
                    <base-badge
                      v-if="groupMemberLoanApplication.kycReviewStatus === 'rejected'"
                      color="primary"
                      label="Corrección documentos"
                      size="xs"
                      class="mr-auto"
                    />
                    <base-badge
                      :color="getMemberBadgeColor(groupMemberLoanApplication)"
                      :label="$t(`groupMemberLoanApplication.aasmState.${groupMemberLoanApplication.aasmState}`)"
                      size="xs"
                      class="mr-auto"
                    />
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Monto solicitado
                    </span>
                    <span>
                      {{ groupMemberLoanApplication.amount ? toCurrency(groupMemberLoanApplication.amount) : '-' }}
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Monto aceptado
                    </span>
                    <span v-if="groupMemberLoanApplication.preApproved">
                      {{ acceptedAmount(groupMemberLoanApplication) }}
                    </span>
                    <span v-else>
                      En revisión
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Pago por mil
                    </span>
                    <span v-if="groupMemberLoanApplication.preApproved && groupMemberLoanApplication">
                      {{ costPerThousand(groupMemberLoanApplication) }}
                    </span>
                    <span v-else>
                      En revisión
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Whatsapp
                    </span>
                    <span>
                      {{ groupMemberLoanApplication.groupMember.user.phoneNumber }}
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Domicilio
                    </span>
                    <span>
                      {{ groupMemberLoanApplication.groupMember.user.address }}
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Buró de crédito
                    </span>
                    <div>
                      <a
                        v-if="groupMemberLoanApplication.creditBureauReportUrl"
                        :href="groupMemberLoanApplication.creditBureauReportUrl"
                        class="cursor-pointer self-start text-blue-500 underline"
                        target="_blank"
                      >
                        Descargar
                      </a>
                      <span v-else>
                        -
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </li>
    </ul>
    <base-empty-state
      v-else
      text="No hay solicitudes pendientes"
      class="mt-10"
    />
  </div>
  <group-promoter-input-form-modal
    :show="showGroupPromoterInputForm"
    :previous-promoter-president-invite="fetchPromoterPresidentInvitesQ.data?.[0]"
    @submit="handleModifyGroupPromoter"
    @close="showGroupPromoterInputForm = false"
  />
</template>
