import { computed, type Ref } from 'vue';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { isFrontIdCard, isBackIdCard, isSelfieIdCard, isAddressProof } from '@/utils/kycDocumentsGuards';

interface UseGroupMemberLoanApplicationKycDocumentsArgs {
  groupMemberLoanApplication: Ref<GroupMemberLoanApplication>;
}

export default function useGroupMemberLoanApplicationKycDocuments(
  { groupMemberLoanApplication }: UseGroupMemberLoanApplicationKycDocumentsArgs,
) {
  const frontIdCard = computed(() => groupMemberLoanApplication.value.kycDocuments.find(isFrontIdCard));
  const backIdCard = computed(() => groupMemberLoanApplication.value.kycDocuments.find(isBackIdCard));
  const selfieIdCard = computed(() => groupMemberLoanApplication.value.kycDocuments.find(isSelfieIdCard));
  const addressProof = computed(() => groupMemberLoanApplication.value.kycDocuments.find(isAddressProof));

  return {
    frontIdCard,
    backIdCard,
    selfieIdCard,
    addressProof,
  };
}
