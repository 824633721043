import type { UseMutationOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { markAsRead, sendReply } from '@/api/crm/whatsapp/conversation';
import { whatsappConversationKey } from '@/api/crm/whatsapp/conversation/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type SendReplyMutationArgs = UseMutationOptions<null, ApiError, string> & {
  recipientPhone: MaybeRef<string>;
  account?: MaybeRef<string>;
  sender?: MaybeRef<string>;
};

function useSendReply({ recipientPhone, account = 'main', sender = 'main', ...options }: SendReplyMutationArgs) {
  return useReactiveMutation({
    ...options,
    mutationFn: (text) => sendReply({
      recipientPhone: unref(recipientPhone),
      account: unref(account),
      sender: unref(sender),
      text,
    }).then(response => response.data),
  });
}

type MarkAsReadMutationArgs = UseMutationOptions<null, ApiError, void> & {
  recipientPhone: MaybeRef<string>;
  account?: MaybeRef<string>;
  sender?: MaybeRef<string>;
};

function useMarkAsRead({ recipientPhone, account = 'main', sender = 'main', ...options }: MarkAsReadMutationArgs) {
  return useReactiveMutation({
    ...options,
    mutationKey: whatsappConversationKey({ recipientPhone, account }),
    mutationFn: () => markAsRead({
      recipientPhone: unref(recipientPhone),
      account: unref(account),
      sender: unref(sender),
    }).then(response => response.data),
  });
}

export { useSendReply, useMarkAsRead };
