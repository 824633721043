import { api } from '@/api';
import addToFormData from '@/utils/addToFormData';

const BASE_PATH = '/api/internal/promoter_president_invites';

interface PromoterPresidentInvite {
  id: number;
  groupId: number | null;
  inviteeId: number;
  phoneNumber?: string;
  knownFrom: string;
  knownSince: string;
  consolidated: boolean | null;
  additionalInformation: string;
  previousCreditInstitution?: string;
  paymentSheet?: {
    fileUrl: string;
  },
  paymentSheetNameMatches: { groupMemberId: number, fullName: string }[] | null;
}

function getAll(inviteeId?: number) {
  return api.get(BASE_PATH, { params: { q: { inviteeIdEq: inviteeId } } });
}

function create(promoterPresidentInvite: Partial<PromoterPresidentInvite>) {
  const formData = new FormData();
  addToFormData(formData, promoterPresidentInvite);

  return api.post<void>(BASE_PATH, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

function update(id: number, promoterPresidentInvite: Partial<PromoterPresidentInvite>) {
  const formData = new FormData();
  addToFormData(formData, promoterPresidentInvite);

  return api.put(`${BASE_PATH}/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export { create, getAll, update };

export type { PromoterPresidentInvite };
