export default {
  circuloCreditReport: {
    paymentFrequency: {
      A: 'Anual',
      B: 'Bimestral',
      C: 'Catorcenal',
      D: 'Deducción de salario',
      E: 'Semestral',
      M: 'Mensual',
      Q: 'Quincenal',
      R: 'Minimo revolventes',
      S: 'Semanal',
      T: 'Trimestral',
      U: 'Único',
    },
    loanType: {
      AA: 'Arrendamiento Automotriz',
      AB: 'Automotriz Bancario',
      AE: 'Física Actividad Empresarial',
      AM: 'Aparatos/Muebles',
      AR: 'Arrendamiento',
      AV: 'Aviación',
      BC: 'Banca Comunal',
      BL: 'Bote/Lancha',
      BR: 'Bienes Raíces',
      CA: 'Compra De Automóvil',
      CC: 'Crédito Al Consumo',
      CF: 'Crédito Fiscal',
      CO: 'Consolidación',
      CP: 'Crédito Personal Al Consumo',
      ED: 'Editorial',
      EQ: 'Equipo',
      FF: 'Fondeo Fira',
      FI: 'Fianza',
      FT: 'Factoraje',
      GS: 'Grupo Solidario',
      HB: 'Hipotecario Bancario',
      HE: 'Préstamo Tipo Home Equity',
      HV: 'Hipotecario ó Vivienda',
      LC: 'Línea de Crédito',
      LR: 'Línea de Crédito Reinstalable',
      MC: 'Mejoras a la Casa',
      NG: 'Préstamo No Garantizado',
      PB: 'Préstamo Personal Bancario',
      PC: 'Procampo',
      PE: 'Préstamo Para Estudiante',
      PG: 'Préstamo Garantizado',
      PQ: 'Préstamo Quirografario',
      PM: 'Préstamo Empresarial',
      PN: 'Préstamo de Nómina',
      PP: 'Préstamo Personal',
      SH: 'Segunda Hipoteca',
      TC: 'Tarjeta De Crédito',
      TD: 'Tarjeta Departamental',
      TG: 'Tarjeta Garantizada',
      TS: 'Tarjeta De Servicios',
      VR: 'Vehículo Recreativo',
      OT: 'Otros',
      NC: 'Desconocido',
    },
    responsibilityType: {
      I: 'Individual',
      M: 'Mancomunado',
      O: 'Obligado Solidario',
      A: 'Aval',
      T: 'Titular con Aval',
    },
  },
  geolocation: {
    label: {
      'group_member_loan_application_submission': 'Solicitud',
      'address_proof': 'Comprobante de domicilio',
      'signed_contract': 'Firma de contrato',
    },
  },
  groupInvitation: {
    status: {
      pending: 'Pendiente',
      accepted: 'Aceptada',
      rejected: 'Rechazada',
    },
    yearsKnown: {
      'one_or_less': '1 año o menos',
      'one_to_two': '1 a 2 años',
      'three_to_five': '3 a 5 años',
      'five_to_ten': '5 a 10 años',
      'ten_or_more': '10 años o más',
    },
    relationship: {
      'close_relative': 'Familiar cercano',
      'distant_relative': 'Familiar lejano',
      'in_law_relative': 'Familiar político',
      partner: 'Pareja',
      friend: 'Amigo',
      neighbor: 'Vecino',
      acquaintance: 'Conocido',
    },
  },
  groupMember: {
    roles: {
      'guest': 'Integrante solidario',
      'vice_president': 'Vicepresidente',
      'president': 'Presidente',
    },
  },
  groupLoanApplication: {
    aasmState: {
      'inviting': 'Invitando',
      'draft': 'Draft',
      'application_in_review': 'En revisión',
      'application_rejected': 'Rechazada',
      'in_kyc': 'KYC',
      'kyc_in_review': 'KYC en revisión',
      'kyc_rejected': 'KYC rechazado',
      'contracts_signature_pending': 'Firmando',
      'contracts_signed': 'Contratos firmados',
      'disbursed': 'Crédito desembolsado',
      'canceled': 'Cancelada',
    },
    rejectionTags: {
      'committee_policy_violation': 'Comité incumple políticas',
      'majority_members_policy_violation': 'Mayoría de integrantes incumple políticas',
      'exceeds_reformation_attempts': 'Supera intentos de re-conformación',
      'excess_delinquent_balance_individual_loans': 'Supera deuda vencida en créditos individuales',
      'excess_delinquent_balance_group_loans': 'Supera deuda vencida en créditos grupales',
      'high_risk_policies_at_limit': 'Alto riesgo, políticas al raz',
      'potential_fraud': 'Tentativa de fraude',
      'online_group': 'Grupo online',
      'half_group_rejected': 'Menos de la mitad del grupo aprueba políticas',
      'delinquent_consolidation_cycle': 'Morosos en su ciclo de consolidación',
      'president_rejected': 'Presidenta incumple políticas',
      'promoter_delinquent_portfolio': 'Mora del asesor supera el límite',
      'promoter_delinquency_non_consolidated_not_allowed':
        'Asesor con morosidad mayor al 4% no puede colocar no consolidados',
      'several_late_payments': 'Presentaron entre 4 a 6 atrasos en su último crédito (no consolidados)',
      'too_many_late_payments': 'Más de 6 atrasos en su último crédito',
      'few_late_payments': 'Entre 1 y 3 atrasos en su último crédito (no consolidados ni semi)',
      'decrement_amounts': 'Entre 4 y 6 atrasos (consolidados). Disminuir montos',
    },
  },
  groupMemberLoanApplication: {
    aasmState: {
      'draft': 'Borrador',
      'submitted': 'Enviada',
      'rejected': 'Rechazada',
      'in_kyc': 'KYC',
      'kyc_submitted': 'KYC enviada',
      'contract_signature_pending': 'Firmando',
      'contract_signed': 'Contrato firmado',
    },
    rejectionTags: {
      'exceeds_maximum_age': 'Excede edad máxima',
      'under_minimum_age': 'Bajo edad mínima',
      'high_risk': 'Riesgo alto',
      'policies_at_risk_limit': 'Políticas al raz',
      'individual_loan_arrears_policy_violation': 'Incumple atrasos en individuales',
      'group_loan_arrears_policy_violation': 'Incumple atrasos en grupales',
      'non_compliance_policies': 'Incumple políticas de crédito (otros)',
      'straw_borrower_attempt': 'Intento de prestanombre',
      'not_consolidated': 'No consolidado',
      'exceeds_maximum_recent_delinquent_payments': 'Más de 2 pagos en atraso en menos de 6 meses',
      'exceeds_maximum_group_delinquent_amount': 'Sobrepasa el máximo de de deuda vencida en grupales',
      'exceeds_maximum_individual_delinquent_amount': 'Sobrepasa el máximo de de deuda vencida en individuales',
      'surname_collision': 'Existe repetición de apellidos entre los integrantes',
      'members_from_different_states': 'Hay miembros que viven en distintos estados',
      'man_not_meeting_requirements': 'Hombre no consolidado en grupo no consolidado',
      'more_than_allowed_men_in_application': 'Hay más de 2 hombres en el grupo',
      'too_many_recent_queries': 'Se ha consultado su buró de crédito más de 8 veces en los últimos meses',
      'exceeds_maximum_recently_opened_delinquent_individual_loans':
        'Tiene deuda vencida en créditos individuales abiertos recientemente',
    },
  },
  kycDocument: {
    kinds: {
      'front_id_card': 'INE (Anverso)',
      'back_id_card': 'INE (Reverso)',
      'selfie_id_card': 'Selfie con INE',
      'address_proof': 'Comprobante de domicilio',
    },
  },
  promoterPresidentInvites: {
    knownFrom: {
      'recommended_by_current_client': 'Lo recomendó una clienta actual',
      'recommended_by_someone': 'Lo recomendadó alguien',
      'contacted_by_someone': 'Lo contactó alguien',
      'worked_with_them_before': 'Trabajó con la presidenta y otras integrantes',
      'worked_with_president_before': 'Trabajó con la presidenta',
      'not_known': 'No las conoce',
    },
    knownSince: {
      'more_than_1_year': 'Más de 1 año',
      'less_than_1_year': 'Menos de un año',
      'less_than_6_months': 'Menos de 6 meses',
      'less_than_3_months': 'Menos de 3 meses',
      'just_met': 'Recién',
    },
  },
  actionLogs: {
    performedBy: {
      'system': 'Sistema',
      'president': 'Presidente',
      'vice_president': 'Vice presidente',
      'guest_member': 'Miembro',
      'reviewer': 'Analista',
    },
    action: {
      'president_created_group_invitation': '<strong>{0}</strong> invitó a {1} ({2}). {3}, se conocen hace {4}',
      'guest_accepted_group_invitation': '<strong>{0}</strong> aceptó la invitación',
      'vice_president_accepted_group_invitation': '<strong>{0}</strong> (vicepresidente) aceptó la invitación',
      'guest_rejected_group_invitation': '<strong>{0}</strong> rechazó la invitación',
      'vice_president_rejected_group_invitation': '<strong>{0}</strong> (vicepresidente) rechazó la invitación',
      'reviewer_approved_address_proof_kyc_document': (
        '<strong>{0}</strong> aprobó el <i>comprobante de domicilio</i> para {1}'
      ),
      'reviewer_passed_to_review_address_proof_kyc_document': (
        '<strong>{0}</strong> pasó a revisión el <i>comprobante de domicilio</i> para {1}'
      ),
      'reviewer_rejected_address_proof_kyc_document': (
        '<strong>{0}</strong> rechazó el <i>comprobante de domicilio</i> para {1}'
      ),
      'reviewer_approved_selfie_id_card_kyc_document': (
        '<strong>{0}</strong> aprobó la <i>selfie con INE</i> para {1}'
      ),
      'reviewer_passed_to_review_selfie_id_card_kyc_document': (
        '<strong>{0}</strong> pasó a revisión la <i>selfie con INE</i> para {1}'
      ),
      'reviewer_rejected_selfie_id_card_kyc_document': (
        '<strong>{0}</strong> rechazó la <i>selfie con INE</i> para {1}'
      ),
      'reviewer_approved_back_id_card_kyc_document': (
        '<strong>{0}</strong> aprobó la <i>INE (reverso)</i> para {1}'
      ),
      'reviewer_passed_to_review_back_id_card_kyc_document': (
        '<strong>{0}</strong> pasó a revisión la <i>INE (reverso)</i> para {1}'
      ),
      'reviewer_rejected_back_id_card_kyc_document': (
        '<strong>{0}</strong> rechazó la <i>INE (reverso)</i> para {1}'
      ),
      'reviewer_approved_front_id_card_kyc_document': (
        '<strong>{0}</strong> aprobó la <i>INE (anverso)</i> para {1}'
      ),
      'reviewer_passed_to_review_front_id_card_kyc_document': (
        '<strong>{0}</strong> pasó a revisión la <i>INE (anverso)</i> para {1}'
      ),
      'reviewer_rejected_front_id_card_kyc_document': (
        '<strong>{0}</strong> rechazó la <i>INE (anverso)</i> para {1}'
      ),
      'president_submitted_group_member_loan_application': (
        '<strong>{0}</strong> (presidente) realizó su solicitud por ${1}'
      ),
      'vice_president_submitted_group_member_loan_application': (
        '<strong>{0}</strong> (vicepresidente) realizó su solicitud por ${1}'
      ),
      'guest_submitted_group_member_loan_application': '<strong>{0}</strong> realizó su solicitud por ${1}',
      'president_submitted_kyc_group_member_loan_application': (
        '<strong>{0}</strong> (presidente) completó su KYC'
      ),
      'vice_president_submitted_kyc_group_member_loan_application': (
        '<strong>{0}</strong> (vicepresidente) completó su KYC'
      ),
      'guest_submitted_kyc_group_member_loan_application': '<strong>{0}</strong> completó su KYC',
      'reviewer_passed_application_to_review_group_loan_application': (
        '<strong>{0}</strong> pasó la solicitud a revisión'
      ),
      'reviewer_reviewed_application_group_loan_application': '<strong>{0}</strong> pasó la solicitud a KYC',
      'reviewer_allowed_reconfiguration_group_loan_application': '<strong>{0}</strong> habilitó la reconformación',
      'reviewer_rejected_group_loan_application': '<strong>{0}</strong> rechazó la solicitud por {1}',
      'reviewer_reviewed_kyc_group_loan_application': '<strong>{0}</strong> liberó los contratos',
      'reviewer_disbursed_group_loan_application': '<strong>{0}</strong> marcó el crédito como desembolsado',
      'reviewer_started_kyc_review_group_loan_application': '<strong>{0}</strong> comenzó la revisión del KYC',
      'reviewer_sent_contract_to_generate_group_loan_application': (
        '<strong>{0}</strong> envió a generar los contratos para {1}'
      ),
      'system_generated_contract_group_loan_application': 'Los contratos fueron generados',
      'president_signed_contract_group_loan_application': '<strong>{0}</strong> (presidente) firmó el contrato',
      'vice_president_signed_contract_group_loan_application': (
        '<strong>{0}</strong> (vicepresidente) firmó el contrato'
      ),
      'guest_signed_contract_group_loan_application': '<strong>{0}</strong> firmó el contrato',
      'president_deactivated_group_member': '<strong>{0}</strong> desactivó a {1}',
      'admin_deactivated_group_member': '<strong>{2}</strong> desactivó a {1}',
      'reviewer_pre_approved_group_member_loan_application': (
        '<strong>{0}</strong> aprobó a {1} por <i>${2}</i> a una tasa de interés <i>%{3}</i>. ' +
        'Consolidado: <i>{4}</i> y condiciones: <i>{5}</i>'
      ),
      'reviewer_pre_rejected_group_member_loan_application': '<strong>{0}</strong> rechazó a {1} porque <i>{2}</i>',
      'reviewer_canceled_group_loan_application': '<strong>{0}</strong> canceló la solicitud por {1}',
      'reviewer_set_review_pending_group_member_loan_application': (
        '<strong>{0}</strong> dejó pendiente la revisión de {1}'
      ),
      'system_passed_application_to_review_group_loan_application': 'La solicitud pasó a revisión',
      'system_started_application_group_loan_application': 'La solicitud fue inicializada',
      'system_signed_all_contracts_group_loan_application': 'Todos los contratos fueron firmados',
      'system_started_kyc_review_group_loan_application': 'Todos los miembros subieron sus documentos: {0}',
      'president_deleted_group_invitation': '<strong>{0}</strong> eliminó la invitación para {1} ({2})',
      'supporter_submitted_group_member_loan_application': (
        '<strong>{2}</strong> ingresó la solicitud de <strong>{0}</strong> por ${1}'
      ),
      'supporter_submitted_kyc_group_member_loan_application': (
        '<strong>{1}</strong> subió el KYC de <strong>{0}</strong>'
      ),
    },
  },
};
