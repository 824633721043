import type { GroupMember } from '@/api/groupMember';
import { api } from '@/api/index';
import type { KycDocument } from '@/api/kycDocument';
import type { Reference } from '@/api/reference';

import type { CCLoan, CCReport } from './CC';

type AasmStates = 'draft'
| 'submitted'
| 'rejected'
| 'in_kyc'
| 'kyc_submitted'
| 'contract_signature_pending'
| 'contract_signed';

interface Form {
  id?: number;
  resourceId?: number;
  resourceType?: string;
  schemaVersion: string;
  createdAt?: string;
  updatedAt?: string;
  data: {
    sections: {
      loanSimulation: {
        title: string;
        order: number;
        fields: {
          amount: {
            order: number;
            label: string;
            value?: number;
          };
          payments: {
            order: number;
            label: string;
            value?: number;
          };
        };
      };
      personalInformation: {
        title: string;
        order: number;
        fields: {
          firstName: {
            order: number;
            label: string;
            value?: string;
          };
          paternalSurname: {
            order: number;
            label: string;
            value?: string;
          };
          maternalSurname: {
            order: number;
            label: string;
            value?: string;
          };
          sex: {
            order: number;
            label: string;
            value?: 'male' | 'female';
          };
          birthDate: {
            order: number;
            label: string;
            value?: string; // ISO date string
          };
          identificationNumber: {
            order: number;
            label: string;
            value?: string;
          };
        };
      };
      address: {
        title: string;
        order: number;
        fields: {
          street: {
            order: number;
            label: string;
            value?: string;
          };
          externalNumber: {
            order: number;
            label: string;
            value?: string;
          };
          internalNumber: {
            order: number;
            label: string;
            value?: string;
          };
          colony: {
            order: number;
            label: string;
            value?: string;
          };
          municipality: {
            order: number;
            label: string;
            value?: string;
          };
          postalCode: {
            order: number;
            label: string;
            value?: string;
          };
          state: {
            order: number;
            label: string;
            value?: string;
          };
        };
      };
      about: {
        title: string;
        order: number;
        fields: {
          civilStatus: {
            order: number;
            label: string;
            value?: 'single' | 'married' | 'divorced' | 'widowed';
          };
          education: {
            order: number;
            label: string;
            value?: 'elementary' | 'junior_high' | 'high_school' | 'bachelor' | 'master' | 'doctorate' | 'none';
          };
        };
      };
      economicActivity: {
        title: string;
        order: number;
        fields: {
          employee: {
            order: number;
            label: string;
            value?: 'yes' | 'no';
          };
          businessType: {
            order: number;
            label: string;
            value?: string;
          };
          dependents: {
            order: number;
            label: string;
            // eslint-disable-next-line no-magic-numbers
            value?: 0 | 1 | 2 | 3 | 4;
          };
          howLongAgoBusinessStarted: {
            order: number;
            label: string;
            value?: 'just_starting' | 'less_than_six_months' | 'less_than_one_year' | 'one_to_two_years' |
              'two_to_three_years' | 'three_to_five_years' | 'five_to_ten_years' | 'ten_years'
          };
          income: {
            order: number;
            label: string;
            value?: number;
          };
          expenses: {
            order: number;
            label: string;
            value?: number;
          };
          paymentAbilities: {
            order: number;
            label: string;
            value?: number;
          };
        };
      };
    };
  };
}

type Section = Form['data']['sections'];

type SectionKey = keyof Section;

type Field = Form['data']['sections'][SectionKey]['fields'];

interface RelationShipWithNewMember {
  id: number;
  relationship: string;
  approved: boolean;
}

interface BaseGroupMemberLoanApplication {
  id: number;
  publicId: number;
  groupMemberId: number;
  groupLoanApplicationId: number;
  amount: number | null;
  maxAmount: number;
  acceptedAmount: number | null;
  interestRate: number;
  aasmState: AasmStates;
  operationsAffirmation: boolean;
  creditConsultationConsent: boolean;
  bankAccountNumber: string | null;
  previousCycleBankAccountNumber: string | null;
  unsignedContractGenerateJobStatus: string | null;
  unsignedContractUrl: string | null;
  signedContractUrl: string | null;
  signatureUrl: string | null;
  contractSigned: boolean;
  selectedPlatform: string | null;
  consolidated: boolean;
  meetsLoanConditions: boolean;
  consolidatedCreditReport: CCReport;
  groupLoans: CCLoan[];
  recentGroupLoans: CCLoan[];
  individualLoans: CCLoan[];
  recentIndividualLoans: CCLoan[];
  creditBureauReportUrl: string | null;
  kycReviewStatus: 'pending_review' | 'approved' | 'rejected';
  applicationSubmittedAt: string | null;
  kycSubmittedAt: string | null;
  preApproved: boolean;
  cycle: number;
  relationshipWithNewMembers: RelationShipWithNewMember[];
  totalRepaymentAmount: number | null;
  repaymentAmount: number | null;
  costPerThousand: number | null;
  income: number | null;
  expenses: number | null;
  loanPaymentAbility: number | null;
  internalLoanPaymentAbility: number | null;
}

interface GroupMemberLoanApplication extends BaseGroupMemberLoanApplication {
  groupMember: GroupMember;
  references: Reference[];
  form: Form;
  kycDocuments: KycDocument[];
}

function get(groupMemberLoanApplicationId: number) {
  const path = `/api/internal/group_member_loan_applications/${groupMemberLoanApplicationId}`;

  return api.get<{ groupMemberLoanApplication: GroupMemberLoanApplication }>(path);
}

function submit(body: object) {
  const path = '/api/internal/group_member_loan_application/submission';

  return api.post<{ form: Form }>(path, body);
}

function createAuthorizationCode() {
  const path = '/api/internal/group_member_loan_application/authorization_code';

  return api.post<void>(path);
}

function createAuthorizationCodeValidation(code: string) {
  const path = '/api/internal/group_member_loan_application/authorization_code_validation';

  return api.post<void>(path, { code });
}

function updateCurpAndAddress(formId: number, body: object) {
  const path = `/api/internal/group_member_loan_applications/curp_and_address_forms/${formId}`;

  return api.put<void>(path, body);
}

export type {
  Field,
  Form,
  Section,
  BaseGroupMemberLoanApplication,
  GroupMemberLoanApplication,
};

export {
  get,
  submit,
  createAuthorizationCode,
  createAuthorizationCodeValidation,
  updateCurpAndAddress,
};
