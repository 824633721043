import { ref, type MaybeRef } from 'vue';

import type { Contract } from '@/api/contract';

const CONTRACTS_BASE_KEY = ['contracts'];

function contractKey(id: MaybeRef<number>) {
  return [...CONTRACTS_BASE_KEY, ref(id)];
}

function contractsKey(
  contractableId: MaybeRef<number>,
  contractableType: MaybeRef<Contract['contractableType']>,
) {
  return [
    ...CONTRACTS_BASE_KEY,
    {
      contractableId: ref(contractableId),
      contractableType: ref(contractableType) },
  ];
}

export { CONTRACTS_BASE_KEY, contractKey, contractsKey };
