<script setup lang="ts">

import { useForm } from 'vee-validate';
import { computed, onMounted, ref, toRef } from 'vue';
import { mixed, number, object } from 'yup';

import { useValidateCurpMutation } from '@/api/curpValidations/mutations';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useFetchGroupMemberLoanApplication } from '@/api/groupMemberLoanApplication/queries';
import { useSubmitGroupMemberLoanApplication } from '@/api/groupMemberLoanApplication/support/mutations';
import { useGetPostalCodeInfoMutation } from '@/api/mexicanPostalCode/mutations';
import BaseButton from '@/components/base-button.vue';
import FormSection from '@/components/support/form-section.vue';
import VeeValidateDateInput from '@/components/vee-validate/vee-validate-date-input.vue';
import VeeValidateFileInput from '@/components/vee-validate/vee-validate-file-input.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';
import useGroupMemberLoanApplicationKycDocuments from '@/composables/useGroupMemberLoanApplicationKycDocuments';
import useUIStore from '@/stores/ui';
import { textFieldSchema, dateFieldSchema } from '@/utils/yupRules';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
}
const props = defineProps<Props>();

const { frontIdCard } = useGroupMemberLoanApplicationKycDocuments({
  groupMemberLoanApplication: toRef(props, 'groupMemberLoanApplication'),
});

const MIN_AMOUNT = 3_000;
const MAX_AMOUNT = computed(() => props.groupMemberLoanApplication.maxAmount);
const MIN_INCOME = 500;
const MAX_INCOME = 100_000;
const SOFT_MIN_INCOME = 1_000;
const SOFT_MAX_INCOME = 40_000;
const POSTAL_CODE_MIN_LENGTH = 5;
const POSTAL_CODE_MAX_LENGTH = 5;
const IDENTIFICATION_NUMBER_LENGTH = 18;

const uiStore = useUIStore();
const coloniesOptions = ref<{ label: string; value: string }[]>([]);

onMounted(() => {
  if (props.groupMemberLoanApplication.form.data.sections.address.fields.colony.value) {
    coloniesOptions.value = [{
      label: props.groupMemberLoanApplication.form.data.sections.address.fields.colony.value,
      value: props.groupMemberLoanApplication.form.data.sections.address.fields.colony.value,
    }];
  }
});

const validationSchema = object().shape({
  frontIdCard: mixed<File>().when('frontIdCardIsPresent', {
    is: () => !frontIdCard.value?.fileUrl,
    then: (schema) => schema.required('INE (Frontal) es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  sections: object().shape({
    loanSimulation: object().shape({
      fields: object().shape({
        amount: object().shape({
          value: number().required().min(MIN_AMOUNT)
            .max(MAX_AMOUNT.value),
        }),
        payments: object().shape({
          value: number().required(),
        }),
      }),
    }),
    personalInformation: object().shape({
      fields: object().shape({
        firstName: object().shape({
          value: textFieldSchema,
        }),
        paternalSurname: object().shape({
          value: textFieldSchema,
        }),
        maternalSurname: object().shape({
          value: textFieldSchema,
        }),
        sex: object().shape({
          value: textFieldSchema.oneOf(['male', 'female']),
        }),
        birthDate: object().shape({
          value: dateFieldSchema,
        }),
        identificationNumber: object().shape({
          value: textFieldSchema.min(IDENTIFICATION_NUMBER_LENGTH)
            .max(IDENTIFICATION_NUMBER_LENGTH),
        }),
      }),
    }),
    address: object().shape({
      fields: object().shape({
        street: object().shape({
          value: textFieldSchema,
        }),
        externalNumber: object().shape({
          value: textFieldSchema,
        }),
        internalNumber: object().shape({
          value: textFieldSchema,
        }),
        colony: object().shape({
          value: textFieldSchema,
        }),
        municipality: object().shape({
          value: textFieldSchema,
        }),
        postalCode: object().shape({
          value: textFieldSchema.min(POSTAL_CODE_MIN_LENGTH)
            .max(POSTAL_CODE_MAX_LENGTH),
        }),
        state: object().shape({
          value: textFieldSchema,
        }),
      }),
    }),
    about: object().shape({
      fields: object().shape({
        civilStatus: object().shape({
          value: textFieldSchema,
        }),
        education: object().shape({
          value: textFieldSchema,
        }),
      }),
    }),
    economicActivity: object().shape({
      fields: object().shape({
        employee: object().shape({
          value: textFieldSchema,
        }),
        businessType: object().shape({
          value: textFieldSchema,
        }),
        dependents: object().shape({
          value: number().required(),
        }),
        howLongAgoBusinessStarted: object().shape({
          value: textFieldSchema,
        }),
        income: object().shape({
          value: number().required().min(MIN_INCOME)
            .max(MAX_INCOME),
        }),
        expenses: object().shape({
          value: number().required().min(MIN_INCOME)
            .max(MAX_INCOME),
        }),
        paymentAbilities: object().shape({
          value: number().required(),
        }),
      }),
    }),
  }),
});

const { handleSubmit, values: formValues, setValues, errors: formErrors } = useForm({
  initialValues: {
    frontIdCard: null,
    sections: props.groupMemberLoanApplication.form.data.sections,
  },
  validationSchema,
});

function buildCustomWarningMessage(value?: number) {
  if (!value) return undefined;

  if (value > SOFT_MIN_INCOME && value < SOFT_MAX_INCOME) {
    return undefined;
  }

  return 'Verifica que el valor esté correcto. Si es así, puedes continuar.';
}

const validateCurpMutation = useValidateCurpMutation({
  onSuccess: (data) => {
    const formattedBirthDate = new Date(data.birthDate);
    setValues({
      sections: {
        personalInformation: {
          fields: {
            firstName: { value: data.firstName },
            paternalSurname: { value: data.paternalSurname },
            maternalSurname: { value: data.maternalSurname },
            birthDate: { value: formattedBirthDate.toISOString().split('T')[0] },
            sex: { value: data.sex },
          },
        },
      },
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al validar la CURP',
      type: 'error',
      position: 'bottom',
    });
  },
});

function handleCurpInput() {
  if (!!formErrors.value['sections.personalInformation.fields.identificationNumber.value']) return;
  if (!formValues.sections.personalInformation.fields.identificationNumber.value) return;

  validateCurpMutation.mutate(formValues.sections.personalInformation.fields.identificationNumber.value);
}

const getPostalCodeInfo = useGetPostalCodeInfoMutation({
  onSuccess: (data) => {
    coloniesOptions.value = data.colonies.map((colony) => ({
      label: colony,
      value: colony,
    }));
    setValues({
      sections: {
        address: {
          fields: {
            municipality: { value: data.municipality },
            colony: { value: data.colonies[0] },
            state: { value: data.stateCode },
          },
        },
      },
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al obtener los datos del código postal',
      type: 'error',
      position: 'bottom',
    });
  },
});

function handlePostalCodeInput() {
  if (!!formErrors.value['sections.address.fields.postalCode.value']) return;
  if (!formValues.sections.address.fields.postalCode.value) return;

  getPostalCodeInfo.mutate(formValues.sections.address.fields.postalCode.value);
}

const fetchGroupMemberLoanApplicationQ = useFetchGroupMemberLoanApplication({
  id: toRef(props.groupMemberLoanApplication, 'id'),
  initialData: props.groupMemberLoanApplication,
});

const submitGroupMemberLoanApplicationMutation = useSubmitGroupMemberLoanApplication({
  groupMemberLoanApplicationId: toRef(props.groupMemberLoanApplication, 'id'),
});

const onSubmit = handleSubmit((values) => {
  submitGroupMemberLoanApplicationMutation.mutate({ form: values });
});

</script>

<template>
  <form
    v-if="fetchGroupMemberLoanApplicationQ.data"
    class="flex flex-col gap-y-6 rounded-lg bg-white p-6"
    @submit="onSubmit"
  >
    <form-section title="Datos del crédito">
      <vee-validate-input
        name="sections.loanSimulation.fields.amount.value"
        type="number"
        label="¿Cuánto dinero solicita?"
      />
      <vee-validate-input
        name="sections.loanSimulation.fields.payments.value"
        type="number"
        label="¿En cuántos pagos?"
        disabled
      />
    </form-section>
    <form-section title="Información personal">
      <vee-validate-file-input
        input-id="front-id"
        name="frontIdCard"
        label="Identificación oficial (anverso)"
        extensions=".JPG, .JPEG, .PNG"
        :initial-file-url="frontIdCard?.fileUrl"
        initial-file-name="INE (frontal)"
      />
      <vee-validate-input
        name="sections.personalInformation.fields.identificationNumber.value"
        label="CURP"
        @blur="handleCurpInput"
      />
      <vee-validate-input
        name="sections.personalInformation.fields.firstName.value"
        label="Nombre(s)"
        disabled
      />
      <vee-validate-input
        name="sections.personalInformation.fields.paternalSurname.value"
        label="Apellido paterno"
        disabled
      />
      <vee-validate-input
        name="sections.personalInformation.fields.maternalSurname.value"
        label="Apellido materno"
        disabled
      />
      <vee-validate-select
        name="sections.personalInformation.fields.sex.value"
        label="Sexo"
        :options="[
          { label: 'Hombre', value: 'male' },
          { label: 'Mujer', value: 'female' },
        ]"
        disabled
      />
      <vee-validate-date-input
        name="sections.personalInformation.fields.birthDate.value"
        label="Fecha de nacimiento"
        disabled
      />
    </form-section>
    <form-section title="Dirección">
      <p
        v-if="!!groupMemberLoanApplication.form.data.sections.address.fields.postalCode.value"
        class="text-red-500"
      >
        Recuerda confirmar que la dirección siga siendo correcta.
      </p>
      <vee-validate-input
        name="sections.address.fields.street.value"
        label="Calle"
      />
      <vee-validate-input
        name="sections.address.fields.externalNumber.value"
        label="Número exterior"
      />
      <vee-validate-input
        name="sections.address.fields.internalNumber.value"
        label="Número interior"
      />
      <vee-validate-input
        name="sections.address.fields.postalCode.value"
        label="Código postal"
        @blur="handlePostalCodeInput"
      />
      <vee-validate-select
        name="sections.address.fields.colony.value"
        label="Colonia"
        :options="coloniesOptions"
      />
      <vee-validate-input
        name="sections.address.fields.municipality.value"
        label="Municipio"
        disabled
      />
      <vee-validate-input
        name="sections.address.fields.state.value"
        label="Estado"
        disabled
      />
    </form-section>
    <form-section title="Sobre la persona">
      <vee-validate-select
        name="sections.about.fields.civilStatus.value"
        label="Estado civil"
        :options="[
          { label: 'Soltero/a', value: 'single' },
          { label: 'Casado/a', value: 'married' },
          { label: 'Divorciado/a', value: 'divorced' },
          { label: 'Viudo/a', value: 'widowed' },
        ]"
      />
      <vee-validate-select
        name="sections.about.fields.education.value"
        label="Nivel máximo de estudios"
        :options="[
          { label: 'Primaria', value: 'elementary' },
          { label: 'Secundaria', value: 'junior_high' },
          { label: 'Preparatoria', value: 'high_school' },
          { label: 'Licenciatura', value: 'bachelor' },
          { label: 'Maestría', value: 'master' },
          { label: 'Doctorado', value: 'doctorate' },
          { label: 'Ninguno', value: 'none' },
        ]"
      />
    </form-section>
    <form-section title="Actividad económica">
      <vee-validate-select
        name="sections.economicActivity.fields.employee.value"
        label="¿Actualmente tienes un trabajo donde seas empleado?"
        :options="[
          { label: 'Sí', value: 'yes' },
          { label: 'No', value: 'no' },
        ]"
      />
      <vee-validate-input
        name="sections.economicActivity.fields.businessType.value"
        label="Si es que tienes un negocio propio, ¿de qué es tu negocio?"
      />
      <vee-validate-select
        name="sections.economicActivity.fields.dependents.value"
        label="¿Tienes dependientes económicos?, ¿cuántos?"
        :options="[
          { label: 'No', value: 0 },
          { label: 'Sí, 1', value: 1 },
          { label: 'Sí, 2', value: 2 },
          { label: 'Sí, 3', value: 3 },
          { label: 'Sí, 4 o más', value: 4 },
        ]"
      />
      <vee-validate-select
        name="sections.economicActivity.fields.howLongAgoBusinessStarted.value"
        label="¿Cuánto tiempo llevas con el negocio?"
        :options="[
          { label: 'Apenas voy empezando', value: 'just_starting' },
          { label: 'Menos de 6 meses', value: 'less_than_six_months' },
          { label: 'Menos de 1 año', value: 'less_than_one_year' },
          { label: '1 a 2 años', value: 'one_to_two_years' },
          { label: '2 a 3 años', value: 'two_to_three_years' },
          { label: '3 a 5 años', value: 'three_to_five_years' },
          { label: '5 a 10 años', value: 'five_to_ten_years' },
          { label: 'Más de 10 años', value: 'ten_years' },
        ]"
      />
      <vee-validate-input
        type="number"
        name="sections.economicActivity.fields.income.value"
        label="Aproximadamente, ¿cuántos son tus ingresos mensuales?"
        :custom-warning-message="buildCustomWarningMessage(formValues.sections.economicActivity.fields.income.value)"
      />
      <vee-validate-input
        type="number"
        name="sections.economicActivity.fields.expenses.value"
        label="Aprimadamente, ¿cuántos son tus gastos mensuales?"
        :custom-warning-message="buildCustomWarningMessage(formValues.sections.economicActivity.fields.expenses.value)"
      />
      <vee-validate-input
        type="number"
        name="sections.economicActivity.fields.paymentAbilities.value"
        label="¿Çuánto es el pago que podrías hacer a la semana para un crédito?"
      />
    </form-section>
    <base-button
      type="submit"
      :loading="submitGroupMemberLoanApplicationMutation.isPending"
      text="Enviar solicitud"
    />
  </form>
</template>
