import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { index, type Contract } from '@/api/contract';
import { contractsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchContractsComposableArgs = {
  contractableId: MaybeRef<number>,
  contractableType: MaybeRef<Contract['contractableType']>,
} & UseQueryOptions<Contract[], ApiError>;

function useFetchContracts({
  contractableId,
  contractableType,
  ...options
}: FetchContractsComposableArgs) {
  return useReactiveQuery({
    queryKey: contractsKey(contractableId, contractableType),
    queryFn: () => index({
      contractableId: unref(contractableId),
      contractableType: unref(contractableType),
    }).then((response) => response.data.contracts),
    ...options,
  });
}

export { useFetchContracts };
