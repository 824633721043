import { api } from '@/api';
import type { User } from '@/api/user';
import { type WhatsappMessage } from '@/api/whatsappMessage';

interface WhatsappConversation {
  recipientPhone: string;
  messages: WhatsappMessage[];
  user: User
  unreadMessagesCount: number;
}

interface GetConversationParams {
  recipientPhone: string;
  account?: string
  sender?: string;
}

function getConversation({ recipientPhone, account = 'main', sender = 'main' }: GetConversationParams) {
  const path = `/api/internal/crm/whatsapp/${account}/${sender}/conversations/${recipientPhone}`;

  return api.get<{ whatsappConversation: WhatsappConversation }>(path);
}

interface SendReplyPayload {
  recipientPhone: string;
  text: string;
  account?: string;
  sender?: string;
}

function sendReply({ recipientPhone, text, account = 'main', sender = 'main' }: SendReplyPayload) {
  const path = `/api/internal/crm/whatsapp/${account}/${sender}/conversations/${recipientPhone}/replies`;

  return api.post<null>(path, { text });
}

interface MarkAsReadPayload {
  recipientPhone: string;
  account?: string;
  sender?: string;
}

function markAsRead({ recipientPhone, account = 'main', sender = 'main' }: MarkAsReadPayload) {
  const path = `/api/internal/crm/whatsapp/${account}/${sender}/conversations/${recipientPhone}/reads`;

  return api.post<null>(path);
}

export type {
  WhatsappMessage,
  WhatsappConversation,
  GetConversationParams,
  SendReplyPayload,
  MarkAsReadPayload,
};

export { getConversation, sendReply, markAsRead };
