<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { object, bool, string } from 'yup';

import { type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import BaseFormSection from '@/components/base-form-section.vue';
import BaseModal from '@/components/base-modal.vue';
import VeeValidateFileInput from '@/components/vee-validate/vee-validate-file-input.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';

const emit = defineEmits(['close', 'submit']);

interface Props {
  show: boolean;
  previousPromoterPresidentInvite?: PromoterPresidentInvite;
}

const props = defineProps<Props>();

// eslint-disable-next-line complexity
const initialValues = computed(() => {
  const promoterPresidentInvite = props.previousPromoterPresidentInvite;

  return {
    knownSince: promoterPresidentInvite?.knownSince || '',
    knownFrom: promoterPresidentInvite?.knownFrom || '',
    consolidated: promoterPresidentInvite?.consolidated ?? null,
    previousCreditInstitution: promoterPresidentInvite?.previousCreditInstitution || '',
    additionalInformation: promoterPresidentInvite?.additionalInformation || '',
    paymentSheet: promoterPresidentInvite?.paymentSheet || undefined,
    paymentSheetFile: undefined,
  };
});

const validationSchema = object({
  knownSince: string().required('Cuéntanos desde cuando los conoces'),
  knownFrom: string().required('Cuéntanos cómo los conoces'),
  consolidated: bool().required('Necesitamos saber si está consolidado o no'),
  additionalInformation: string().required('Nos interesa saber cualquier otra información relevante'),
  previousCreditInstitution: string().when('consolidated', {
    is: true,
    then: (schema) => schema.required('Indícanos de qué financiera vienen'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const paymentSheetUrl = computed<string | undefined>(() => initialValues.value?.paymentSheet?.fileUrl);
const { values, handleSubmit, resetForm } = useForm(
  { validationSchema, initialValues: initialValues.value, keepValuesOnUnmount: true },
);

const submit = handleSubmit(() => {
  if (!values.consolidated) {
    values.previousCreditInstitution = '';
    values.paymentSheetFile = undefined;
  }

  emit('submit', { ...values });
  resetForm();
});

</script>

<template>
  <base-modal
    :show="show"
    accept-button-text="Guardar información"
    @accept="submit"
    @close="emit('close')"
  >
    <template #body>
      <form class="flex flex-col space-y-5">
        <base-form-section
          title="Cuéntanos más"
        >
          <vee-validate-select
            label="¿Hace cuánto conoces al grupo?"
            name="knownSince"
            :options="[
              { value: 'just_met', label: 'Me acaban de contactar' },
              { value: 'less_than_3_months', label: '3 meses o menos' },
              { value: 'less_than_6_months', label: '6 meses o menos' },
              { value: 'less_than_1_year', label: '1 año o menos' },
              { value: 'more_than_1_year', label: 'Más de 1 año' },
            ]"
            :with-undefined-value="false"
          />
          <vee-validate-select
            label="¿Cómo conoces al grupo?"
            name="knownFrom"
            :options="[
              { value: 'not_known', label: 'No los conozco' },
              { value: 'recommended_by_current_client', label: 'Me lo recomendó un cliente actual' },
              { value: 'recommended_by_someone', label: 'Me lo recomendó otra persona' },
              { value: 'contacted_by_someone', label: 'Me contactaron' },
              { value: 'worked_with_president_before', label: 'He trabajado solo con la presidenta' },
              { value: 'worked_with_them_before', label: 'He trabajado con la presidenta y otras integrantes' },
            ]"
            :with-undefined-value="false"
          />
          <vee-validate-select
            label="¿Es un grupo consolidado?"
            name="consolidated"
            :options="[
              { value: false, label: 'No' },
              { value: true, label: 'Si' },
            ]"
          />
          <div
            v-if="values.consolidated"
            class="flex flex-col space-y-5"
          >
            <vee-validate-input
              label="¿De qué financiera vienen?"
              name="previousCreditInstitution"
            />
            <vee-validate-file-input
              :initial-file-url="paymentSheetUrl"
              label="Sube las hojas de pago"
              description="Esto en caso de que no estén en su historial de crédito.
                           Agregarla acelerará la respuesta sobre la solicitud"
              name="paymentSheetFile"
              extensions=".JPG, .PNG, .JPEG"
              input-id="paymentSheet"
            />
          </div>
          <vee-validate-input
            label="Información adicional"
            name="additionalInformation"
            placeholder="Con quiénes has trabajado, hace cuánto tiempo, cómo pagan, etc."
          />
        </base-form-section>
      </form>
    </template>
  </base-modal>
</template>

