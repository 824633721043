import { api } from '@/api';
import type { ActionLog } from '@/api/actionLog';

const BASE_PATH = '/api/internal/group_loan_applications';

function get(groupLoanApplicationId: number) {
  const path = `${BASE_PATH}/${groupLoanApplicationId}/action_logs`;

  return api.get<{ actionLogs: ActionLog[] }>(path);
}

export { get };
