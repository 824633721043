<script setup lang="ts">
import { BellIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import {
  useFetchActionLogs as useFetchGroupLoanApplicationActionLogs,
} from '@/api/groupLoanApplication/actionLog/queries';
import ActionLogs from '@/components/action-logs.vue';
import BaseButton from '@/components/base-button.vue';

interface Props {
  title?: string;
  groupLoanApplication: GroupLoanApplication
}

const props = defineProps<Props>();

const actionLogsQ = useFetchGroupLoanApplicationActionLogs({ groupLoanApplicationId: props.groupLoanApplication.id });
const isActionLogsOpen = ref(false);
</script>
<template>
  <div class="relative w-auto">
    <base-button
      variant="white"
      :loading="actionLogsQ.isFetching"
      :left-icon="BellIcon"
      :disabled="!actionLogsQ.data?.length"
      text="Ver historial de cambios"
      @click="isActionLogsOpen = !isActionLogsOpen"
    />
    <div
      v-if="!!actionLogsQ.data?.length && isActionLogsOpen"
      class="absolute -left-24 top-12 z-20 h-64 w-96 overflow-y-auto rounded-lg border border-gray-200 bg-white p-4 shadow-lg"
    >
      <action-logs :action-logs="actionLogsQ.data" />
    </div>
  </div>
</template>
