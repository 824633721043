<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { ref } from 'vue';
import { object, string } from 'yup';

import { type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import { useCreatePromoterPresidentInvite } from '@/api/promoterPresidentInvite/mutations';
import BasePhoneInput from '@/components/base-phone-input.vue';
import GroupPromoterInputFormModal from '@/components/promoters/group-promoter-input-form-modal.vue';
import RailsFlashAlerts from '@/components/rails-flash-alerts.vue';
import VForm from '@/components/v-form.vue';
import useUIStore from '@/stores/ui';

const showGroupPromoterInput = ref(false);
const phoneNumber = ref('');

const validationSchema = object({
  phoneNumber: string().mobilePhone(['es-MX', 'es-CL']).required('Número de teléfono es obligatorio'),
});

const uiStore = useUIStore();

const createPromoterPresidentInviteMutation = useCreatePromoterPresidentInvite({
  onSuccess: () => {
    uiStore.toast({
      message: 'Invitación enviada',
      type: 'success',
      position: 'top',
    });
    showGroupPromoterInput.value = false;
    phoneNumber.value = '';
  },
  onError: (err) => {
    uiStore.toast({
      message: err.response?.data?.detail || 'Hubo un error al enviar la invitación',
      type: 'error',
      position: 'top',
    });
    showGroupPromoterInput.value = false;
  },
});

function handleSubmit(values: PromoterPresidentInvite) {
  const promoterPresidentInviteData = {
    ...values,
    phoneNumber: phoneNumber.value,
  };

  createPromoterPresidentInviteMutation.mutate(promoterPresidentInviteData);
}
</script>

<template>
  <div class="flex w-full max-w-md flex-col">
    <rails-flash-alerts />
    <v-form
      class="flex flex-col rounded-lg bg-gray-100 p-2"
      :validation-schema="validationSchema"
      @submit="showGroupPromoterInput = true"
    >
      <h2 class="text-lg font-medium">
        Invitar a una presidenta a crear un grupo
      </h2>
      <base-phone-input
        v-model="phoneNumber"
        label="Número de la presidenta"
        description="Ingresa el número de la presidenta y le llegará
                    un mensaje a Whatsapp con la información para crear su grupo"
        name="phoneNumber"
        class="mt-8"
      />
      <base-button
        text="Enviar invitación"
        type="submit"
        class="mt-4"
      />
    </v-form>
    <group-promoter-input-form-modal
      :show="showGroupPromoterInput"
      @submit="handleSubmit"
    />
    <a
      class="mt-10 flex flex-row items-center justify-between rounded-lg bg-gray-200 p-2"
      href="/promoters/group_loan_applications"
    >
      <div class="flex flex-row items-center gap-x-2">
        <span class="text-xl">
          📱
        </span>
        <h2 class="text-lg">
          Solicitudes en proceso
        </h2>
      </div>
      <ChevronRightIcon
        class="ml-auto size-5"
      />
    </a>
    <a
      class="mt-4 flex flex-row items-center justify-between rounded-lg bg-gray-200 p-2"
      href="/promoters/group_loans"
    >
      <div class="flex flex-row items-center gap-x-2">
        <span class="text-xl">
          💸
        </span>
        <h2 class="text-lg">
          Grupos activos
        </h2>
      </div>
      <ChevronRightIcon
        class="ml-auto size-5"
      />
    </a>
  </div>
</template>
