<script setup lang="ts">
import { computed } from 'vue';

import { useFetchCreditPolicyResult } from '@/api/groupLoanApplication/creditPolicyResult/queries';
import BaseBadge from '@/components/base-badge.vue';

interface Props {
  groupLoanApplicationId: number;
}

const props = defineProps<Props>();

const creditPolicyResultQ = useFetchCreditPolicyResult({ groupLoanApplicationId: props.groupLoanApplicationId });

const hardDealBreakerPolicies = computed(
  () => creditPolicyResultQ.data?.policies?.filter((policy) => !policy.approved && policy.hardDealBreaker) ?? [],
);
const softDealBreakerPolicies = computed(
  () => creditPolicyResultQ.data?.policies?.filter((policy) => !policy.approved && policy.softDealBreaker) ?? [],
);
</script>
<template>
  <div class="flex items-center justify-between">
    <div v-if="hardDealBreakerPolicies?.length > 0 || softDealBreakerPolicies?.length > 0">
      <p
        v-if="!creditPolicyResultQ.data?.approved"
        class="font-semibold"
      >
        Se sugiere rechazar
      </p>
      <div class="flex flex-col gap-y-1">
        <p
          v-if="creditPolicyResultQ.data?.approved"
          className="text-sm"
        >
          Detectamos que se incumplen las siguientes políticas
        </p>
        <div class="flex flex-wrap gap-x-3 gap-y-1">
          <base-badge
            v-for="hardDealBreakerPolicy in hardDealBreakerPolicies"
            :key="hardDealBreakerPolicy.id"
            size="xs"
            color="red"
          >
            {{ $t(`groupLoanApplication.rejectionTags.${hardDealBreakerPolicy.rejectionTag}`) }}
          </base-badge>
          <base-badge
            v-for="softDealBreakerPolicy in softDealBreakerPolicies"
            :key="softDealBreakerPolicy.id"
            size="xs"
            color="yellow"
          >
            {{ $t(`groupLoanApplication.rejectionTags.${softDealBreakerPolicy.rejectionTag}`) }}
          </base-badge>
        </div>
      </div>
    </div>
  </div>
</template>
