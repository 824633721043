import { api } from '@/api';

interface Contract {
  id: number;
  contractableId: number;
  contractableType: 'GroupLoanApplication';
  aasmState: 'unsigned' | 'signed';
  unsignedDocumentFileUrl: string | null;
  signedDocumentFileUrl: string | null;
  createdAt: string;
  updatedAt: string;
}

const BASE_PATH = '/api/internal/contracts';

type IndexArgs = {
  contractableId: number;
  contractableType: Contract['contractableType'];
};

function index(params: IndexArgs) {
  return api.get<{ contracts: Contract[] }>(BASE_PATH, { params });
}

export type { Contract };
export { index };
