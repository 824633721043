<script setup lang="ts">
import { ClockIcon } from '@heroicons/vue/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { computed, type Ref } from 'vue';

import type { IUser } from '@/api/currentUser';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useFetchCreditPolicyResult } from '@/api/groupMemberLoanApplication/creditPolicyResult/queries';
import { useFetchTags } from '@/api/tags/queries';
import BaseBadge from '@/components/base-badge.vue';
import useStrictInject from '@/composables/useStrictInject';
import { toCurrency } from '@/utils/filters';
import { currentUserKey } from '@/utils/keys';

const currentUser = useStrictInject<Ref<IUser>>(currentUserKey);

const props = defineProps<{
  groupMemberLoanApplication: GroupMemberLoanApplication
}>();

const groupMember = computed(() => props.groupMemberLoanApplication.groupMember);
const user = computed(() => groupMember.value.user);

const canGoToSupportForm = computed(() => (
  ['draft', 'in_kyc', 'contract_signature_pending'].includes(props.groupMemberLoanApplication.aasmState)
));

const groupMemberLoanApplicationId = computed(() => props.groupMemberLoanApplication.id);

const rejectionTagsQ = useFetchTags({
  resourceId: groupMemberLoanApplicationId,
  resourceType: 'GroupMemberLoanApplication',
  context: 'rejection_tags',
});

const creditPolicyResultQ = useFetchCreditPolicyResult({ groupMemberLoanApplicationId });
const hardDealBreakerPolicies = computed(
  () => creditPolicyResultQ.data?.policies?.filter((policy) => !policy.approved && policy.hardDealBreaker) ?? [],
);
const softDealBreakerPolicies = computed(
  () => creditPolicyResultQ.data?.policies?.filter((policy) => !policy.approved && policy.softDealBreaker) ?? [],
);

const personalInformationItems = computed(() => {
  const { personalInformation } = props.groupMemberLoanApplication.form.data.sections;
  const { civilStatus, education } = props.groupMemberLoanApplication.form.data.sections.about.fields;

  return [
    { label: 'Correo electrónico', value: user.value.email },
    { label: 'Estado', value: user.value.state },
    { label: 'Municipio', value: user.value.municipality },
    { label: 'Colonia', value: user.value.colony },
    { label: 'Calle', value: user.value.street },
    { label: 'Número exterior', value: user.value.externalNumber },
    { label: 'Número interior', value: user.value.internalNumber },
    { label: 'Código postal', value: user.value.postalCode },
    { label: 'Sexo de nacimiento', value: personalInformation.fields.sex.value },
    { label: 'Fecha de nacimiento', value: personalInformation.fields.birthDate.value },
    { label: 'CURP', value: personalInformation.fields.identificationNumber.value },
    { label: 'Estado civil', value: civilStatus.value },
    { label: 'Nivel máximo de estudios', value: education.value },
  ];
});
</script>

<template>
  <div class="flex flex-col gap-y-10 bg-gray-100 p-2">
    <div class="flex items-center gap-x-8">
      <div
        v-if="groupMemberLoanApplication.applicationSubmittedAt"
        class="flex items-center gap-x-1"
      >
        <ClockIcon
          class="size-4 text-primary-600/70"
        />
        <p class="text-sm text-gray-700">
          Solicitud enviada
          {{
            formatDistanceToNow(new Date(groupMemberLoanApplication.applicationSubmittedAt), { addSuffix: true })
          }}
        </p>
      </div>
      <p
        v-else
        class="font-semibold text-gray-700"
      >
        La solicitud aún no ha sido enviada
      </p>
      <div
        v-if="rejectionTagsQ.data && rejectionTagsQ.data.length > 0"
        className="flex gap-x-2 items-center"
      >
        <h3 class="text-sm font-bold text-gray-700">
          Motivos de rechazo:
        </h3>
        <base-badge
          v-for="rejectionTag in rejectionTagsQ.data"
          :key="rejectionTag.id"
          color="red"
          :label="$t(`groupMemberLoanApplication.rejectionTags.${rejectionTag.name}`)"
          size="xs"
        />
      </div>
    </div>
    <div class="flex justify-between">
      <div class="flex flex-col">
        <h2 class="text-2xl font-bold">
          {{ user.fullName }}
        </h2>
        <div class="flex items-center gap-x-4 text-sm text-gray-700">
          <p><span class="font-semibold">Teléfono:</span> {{ user.phoneNumber }}</p>
          <p><span class="font-semibold">ID:</span> {{ groupMemberLoanApplication.id }}</p>
          <p><span class="font-semibold">Public ID:</span> {{ groupMemberLoanApplication.publicId }}</p>
        </div>
        <div
          v-if="!!groupMember.invitation"
          class="flex items-center gap-x-4 text-sm text-gray-700"
        >
          <p><span class="font-bold">Relación con presidenta: </span>{{ groupMember.invitation.relationship }}</p>
          <p><span class="font-bold">Desde hace: </span>{{ groupMember.invitation.humanYearsKnown }}</p>
        </div>
      </div>
      <div class="flex items-center gap-x-4 self-start">
        <a
          v-if="currentUser.support && canGoToSupportForm"
          :href="`/support/group_member_loan_applications/${groupMemberLoanApplication.id}`"
          class="text-primary-600"
          target="_blank"
        >
          Ir al formularo de soporte
        </a>
        <base-button
          :href="`https://wa.me/${user.phoneNumber}`"
          text="Abrir chat de WhatsApp"
          variant="white"
        />
      </div>
    </div>
    <div class="flex flex-col justify-between gap-y-4 md:flex-row">
      <div class="flex flex-col gap-y-3">
        <div class="flex gap-x-7 font-medium md:gap-x-12">
          <div class="flex flex-col gap-y-1">
            <h3 class="text-sm text-gray-700">
              📝 Monto solicitado
            </h3>
            <p class="text-lg">
              {{ groupMemberLoanApplication.amount ? toCurrency(groupMemberLoanApplication.amount) : '-' }}
            </p>
          </div>
          <div class="flex flex-col gap-y-1">
            <h3 class="text-sm text-gray-700">
              💰 Monto otorgado
            </h3>
            <p class="text-lg">
              {{
                groupMemberLoanApplication.acceptedAmount && groupMemberLoanApplication.preApproved ?
                  toCurrency(groupMemberLoanApplication.acceptedAmount) : '-'
              }}
            </p>
          </div>
          <div class="flex flex-col gap-y-1">
            <h3 class="text-sm text-gray-700">
              📌 Tasa de interés
            </h3>
            <p class="text-lg">
              {{
                groupMemberLoanApplication.interestRate ?
                  (groupMemberLoanApplication.interestRate * 100).toFixed(2) : '-'
              }}%
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-1 items-center justify-between">
      <div v-if="hardDealBreakerPolicies?.length > 0 || softDealBreakerPolicies?.length > 0">
        <p
          v-if="!creditPolicyResultQ.data?.approved"
          class="font-semibold"
        >
          Se sugiere rechazar
        </p>
        <div class="flex flex-col gap-y-1">
          <p
            v-if="creditPolicyResultQ.data?.approved"
            className="text-sm"
          >
            Detectamos que se incumplen las siguientes políticas
          </p>
          <div class="flex flex-wrap gap-x-3 gap-y-1">
            <base-badge
              v-for="hardDealBreakerPolicy in hardDealBreakerPolicies"
              :key="hardDealBreakerPolicy.id"
              size="xs"
              color="red"
            >
              {{ $t(`groupMemberLoanApplication.rejectionTags.${hardDealBreakerPolicy.rejectionTag}`) }}
            </base-badge>
            <base-badge
              v-for="softDealBreakerPolicy in softDealBreakerPolicies"
              :key="softDealBreakerPolicy.id"
              size="xs"
              color="yellow"
            >
              {{ $t(`groupMemberLoanApplication.rejectionTags.${softDealBreakerPolicy.rejectionTag}`) }}
            </base-badge>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-12">
      <ul class="flex flex-col gap-y-1">
        <h3 class="text-sm font-semibold text-primary-600">
          Datos personales
        </h3>
        <li
          v-for="item in personalInformationItems"
          :key="item.label"
          class="text-xs"
        >
          <span class="font-bold">
            {{ item.label }}
          </span>: {{ item.value }}
        </li>
      </ul>
      <ul class="flex flex-col gap-y-1">
        <h3 class="mb-3 text-sm font-semibold text-primary-600">
          Información laboral
        </h3>
        <li
          v-for="item in groupMemberLoanApplication.form.data.sections.economicActivity.fields"
          :key="item.label"
          class="text-xs"
        >
          <span class="font-bold">
            {{ item.label }}
          </span>: {{ item.value }}
        </li>
      </ul>
    </div>
  </div>
</template>
