import { ref, type MaybeRef } from 'vue';

const WHATSAPP_CONVERSATIONS_BASE_KEY = ['whatsappConversations'];

interface WhatsappConversationKeyParams {
  recipientPhone: MaybeRef<string>;
  account?: MaybeRef<string>;
  sender?: MaybeRef<string>;
}

function whatsappConversationKey({ recipientPhone, account, sender }: WhatsappConversationKeyParams) {
  return [
    ...WHATSAPP_CONVERSATIONS_BASE_KEY,
    {
      phoneNumber: ref(recipientPhone),
      account: ref(account),
      sender: ref(sender),
    }]
  ;
}

export { WHATSAPP_CONVERSATIONS_BASE_KEY, whatsappConversationKey };
