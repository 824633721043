/* eslint-disable */
export default class LoanUtils {
  private static readonly IVA = 1.16;

  static calculateLoanPayment(
    principal: number,
    interestRate: number,
    numberOfPayments: number,
    repaymentPeriodsPerYear: number = 52,
  ): number {
    const ratePerPeriod = (interestRate * this.IVA) / repaymentPeriodsPerYear;
    const payment = principal * (ratePerPeriod * Math.pow(1 + ratePerPeriod, numberOfPayments)) /
           (Math.pow(1 + ratePerPeriod, numberOfPayments) - 1);

    return parseFloat(payment.toFixed(2));
  }

  static calculateInterestPayment(
    principal: number,
    interestRate: number,
    repaymentPeriodsPerYear: number,
  ): number {
    const ratePerPeriod = (interestRate * this.IVA) / repaymentPeriodsPerYear;

    return principal * ratePerPeriod;
  }

  static costPerThousand(
    interestRate: number,
    numberOfPayments: number,
    repaymentPeriodsPerYear: number = 52,
  ): number {
    const interest = this.calculateLoanPayment(
      1000,
      interestRate,
      numberOfPayments,
      repaymentPeriodsPerYear,
    ) * numberOfPayments - 1000;

    const cost = (interest + 1000) / numberOfPayments;

    return parseFloat(cost.toFixed(2));
  }

  static calculateIRR(cashFlows: number[]): number {
    const epsilon = 1.0e-6;
    const maxIterations = 1000;
    let irr = 0.0;
    let delta = 1;

    for (let iter = 0; iter < maxIterations; iter++) {
      let npv = 0.0;
      let dnpv = 0.0;

      cashFlows.forEach((cf, i) => {
        npv += cf / Math.pow(1 + irr, i);
        dnpv -= i * cf / Math.pow(1 + irr, i + 1);
      });

      if (Math.abs(npv) <= epsilon) {
        break;
      }

      const newIrr = irr - npv / dnpv;
      delta = Math.abs(newIrr - irr);
      irr = newIrr;

      if (delta <= epsilon) {
        break;
      }
    }

    return irr;
  }

  static calculateCAT(
    loanAmount: number,
    interestRate: number,
    numberOfPayments: number,
    repaymentPeriodsPerYear: number = 52,
  ): number {
    const loanRepaymentAmount = Math.ceil(this.calculateLoanPayment(
      loanAmount,
      interestRate * this.IVA,
      numberOfPayments,
      repaymentPeriodsPerYear,
    ));

    const cashFlows: number[] = [];
    let finalBalance = loanAmount;

    for (let period = 0; period < numberOfPayments; period++) {
      let balance = finalBalance;
      balance = balance < 0 ? 0 : balance;

      const interestPlusIva = Math.round(this.calculateInterestPayment(
        balance,
        interestRate * this.IVA,
        repaymentPeriodsPerYear,
      ));
      const interestWithoutIva = (balance * interestRate) / repaymentPeriodsPerYear;
      const capital = loanRepaymentAmount - interestPlusIva;
      const cashFlow = capital + interestWithoutIva;
      finalBalance -= capital;

      cashFlows.push(Math.round(cashFlow));
    }

    const irr = this.calculateIRR([-loanAmount, ...cashFlows]);

    return Math.pow(1 + irr, repaymentPeriodsPerYear) - 1;
  }
}
