import { useInfiniteQuery, type UseQueryOptions } from '@tanstack/vue-query';
import { unref, toRef, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import {
  getAll,
  get,
  type GroupLoanApplication,
  type FetchGroupLoanApplicationsRansackParams,
  type GetAllResponse,
} from '@/api/groupLoanApplication';
import { groupLoanApplicationKey, groupLoanApplicationsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

const DEFAULT_PAGE_SIZE = 10;

type FetchGroupLoanApplicationsComposableArgs = {
  q?: FetchGroupLoanApplicationsRansackParams;
  pageSize?: number;
} & UseQueryOptions<GroupLoanApplication, ApiError>;

function useFetchGroupLoanApplications({
  q = {},
  pageSize = DEFAULT_PAGE_SIZE,
}: FetchGroupLoanApplicationsComposableArgs) {
  return useInfiniteQuery<GetAllResponse, ApiError>({
    queryKey: groupLoanApplicationsKey(q),
    queryFn: ({ pageParam }) => getAll({
      q,
      page: { number: pageParam as number, size: pageSize },
    }).then((response) => response.data),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: 1,
  });
}

type FetchGroupLoanApplicationComposableArgs = {
  id: MaybeRef<number>
} & UseQueryOptions<GroupLoanApplication, ApiError>;

function useFetchGroupLoanApplication({
  id, ...options
}: FetchGroupLoanApplicationComposableArgs) {
  return useReactiveQuery({
    queryKey: groupLoanApplicationKey(toRef(id)),
    queryFn: () => get(unref(id)).then(response => response.data.groupLoanApplication),
    ...options,
  });
}

export { useFetchGroupLoanApplications, useFetchGroupLoanApplication };
