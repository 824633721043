<script setup lang="ts">
import { VuePDF, usePDF } from '@tato30/vue-pdf';
import { ref, computed } from 'vue';

import BaseButton from '@/components/base-button.vue';
import BaseSpinner from '@/components/base-spinner.vue';

interface Props {
  url: string;
}
const props = defineProps<Props>();

const { pdf, pages } = usePDF(props.url);
const page = ref(1);

const isPdfLoaded = computed(() => Boolean(pdf.value));

</script>

<template>
  <div class="flex flex-col">
    <a
      class="flex w-full items-center justify-center overflow-scroll rounded-lg border border-primary-600 focus:outline-none"
      :href="url"
      target="_blank"
    >
      <VuePDF
        v-if="isPdfLoaded"
        :pdf="pdf"
        :page="page"
        fit-parent
        v-bind="$attrs"
      />
      <div
        v-else
        class="flex size-full flex-col items-center justify-center py-12"
      >
        <base-spinner
          :size="12"
        />
        <span class="mt-2 text-sm font-medium text-gray-500">
          Cargando el documento...
        </span>
      </div>
    </a>
    <span class="mt-1 text-center text-xs italic text-gray-600">
      Haz click en el documento para abrirlo en otra pestaña
    </span>
    <div class="mt-6 flex flex-row items-center gap-x-4 self-center">
      <base-button
        text="Prev"
        class="w-20"
        size="sm"
        variant="white"
        type="button"
        @click="page = page > 1 ? page - 1 : page"
      />
      <span class="text-sm text-gray-700">página {{ page }} / {{ pages }}</span>
      <base-button
        text="Sig"
        class="w-20"
        size="sm"
        variant="white"
        type="button"
        @click="page = page < pages ? page + 1 : page"
      />
    </div>
  </div>
</template>
