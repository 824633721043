import { api } from '@/api';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { AddressProof } from '@/api/kycDocument';

const BASE_PATH = '/api/internal/geolocations';

type GeolocatableType = 'GroupMemberLoanApplication' | 'AddressProof';

interface Geolocation {
  updatedAt: string;
  geolocatableType: GeolocatableType;
  geolocatableId: number;
  accuracy: number;
  altitudeAccuracy?: number;
  altitude?: number;
  heading?: number;
  speed?: number;
  latitude: string;
  longitude: string;
  id: number;
  geolocatable: GroupMemberLoanApplication | AddressProof;
  label: 'group_member_loan_application_submission' | 'signed_contract' | 'address_proof';
}

function getAll(geolocatableIds: number[], geolocatableType: GeolocatableType) {
  const arrayParams = geolocatableIds.map((id) => `geolocatable_ids[]=${id}`).join('&');

  return api.get<{ geolocations: Geolocation[] }>(
    `${BASE_PATH}?${arrayParams}&geolocatable_type=${geolocatableType}`,
  );
}

export { getAll };
export type { Geolocation, GeolocatableType };

