import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef, ref } from 'vue';

import type { ApiError } from '@/api';
import { getAll, type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import { promoterPresidentInvitesKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchPromoterPresidentInvitesComposableArgs = {
  inviteeId?: MaybeRef<number | undefined>
} & UseQueryOptions<PromoterPresidentInvite[], ApiError>;

function useFetchPromoterPresidentInvites({ inviteeId, ...options }: FetchPromoterPresidentInvitesComposableArgs = {}) {
  return useReactiveQuery({
    queryKey: promoterPresidentInvitesKey(ref(inviteeId)),
    queryFn: () => getAll(unref(inviteeId)).then((response) => response.data.promoterPresidentInvites),
    ...options,
  });
}

export { useFetchPromoterPresidentInvites };
