import { type UseQueryOptions } from '@tanstack/vue-query';
import { unref, toRef, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import type { ActionLog } from '@/api/actionLog';
import { get } from '@/api/groupLoanApplication/actionLog';
import { actionLogsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchActionLogsComposableArgs = {
  groupLoanApplicationId: MaybeRef<number>
} & UseQueryOptions<ActionLog[], ApiError>;

function useFetchActionLogs(
  { groupLoanApplicationId, ...options }: FetchActionLogsComposableArgs,
) {
  return useReactiveQuery({
    queryKey: actionLogsKey(toRef(groupLoanApplicationId)),
    queryFn: () => get(unref(groupLoanApplicationId)).then((response) => response.data.actionLogs),
    ...options,
  });
}

export { useFetchActionLogs };
