import { api } from '@/api';
import type { GroupLoanApplicationCreditPolicyResult } from '@/api/groupLoanApplicationCreditPolicyResult';

const BASE_PATH = '/api/internal/group_loan_applications';

function get(groupLoanApplicationId: number) {
  const path = `${BASE_PATH}/${groupLoanApplicationId}/credit_policy_result`;

  return api.get<{groupLoanApplicationCreditPolicyResult: GroupLoanApplicationCreditPolicyResult}>(path);
}

export { get };
