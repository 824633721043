<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useQueries } from '@tanstack/vue-query';
import { format, parseISO } from 'date-fns';
import { ref, computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { AdvancedMarker, InfoWindow } from 'vue3-google-map';

import type { Geolocation } from '@/api/geolocation';
import { useFetchGeolocations } from '@/api/geolocation/queries';
import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import type { GroupMember } from '@/api/groupMember';
import { type AddressProof, index as kycDocumentsIndex } from '@/api/kycDocument';
import { documentableKycDocuments } from '@/api/queryKeys';
import BaseGoogleMap from '@/components/base-google-map.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import { isAddressProof } from '@/utils/kycDocumentsGuards';

interface Props {
  groupLoanApplication: GroupLoanApplication
}

const props = defineProps<Props>();

const COLORS = [
  '#1F78B4',
  '#33A02C',
  '#6A3D9A',
  '#E31A1C',
  '#A6CEE3',
  '#B2DF8A',
  '#FB9A99',
  '#FDBF6F',
  '#CAB2D6',
  '#FF7F00',
  '#8B4513',
  '#4682B4',
  '#D2691E',
  '#9ACD32',
  '#FF4500',
];
const GEOLOCATION_LABEL_GLYPH_COLOR: Record<Geolocation['label'], string> = {
  'group_member_loan_application_submission': '#000000',
  'address_proof': '#FF7F00',
  'signed_contract': '#9ACD32',
};

const { t } = useI18n();
const displayAddressProofGeolocations = computed(
  () => !['inviting', 'draft', 'application_in_review'].includes(props.groupLoanApplication.aasmState) ||
    props.groupLoanApplication.cycle !== 1,
);
const displaySignedContractGeolocations = computed(
  () => ![
    'inviting', 'draft', 'application_in_review', 'in_kyc', 'kyc_in_review',
  ].includes(props.groupLoanApplication.aasmState),
);
const filterValues = reactive<Record<string, boolean>>({
  'address_proof': displayAddressProofGeolocations.value,
  'group_member_loan_application_submission': true,
  'signed_contract': displaySignedContractGeolocations.value,
  ...props.groupLoanApplication.memberLoanApplications.reduce(
    (acc, app) => ({ ...acc, [app.id.toString()]: true }),
    {},
  ),
});

const groupMemberLoanApplicationIds = computed(
  () => props.groupLoanApplication.memberLoanApplications.map((memberLoanApplication) => memberLoanApplication.id),
);

const kycDocumentsBuiltQ = computed(() => groupMemberLoanApplicationIds.value.map((id) => {
  const params = { documentableId: id, documentableType: 'GroupMemberLoanApplication' };

  return {
    queryKey: documentableKycDocuments(params),
    queryFn: () => kycDocumentsIndex(params).then((response) => response.data.kycDocuments),
  };
}));
const allKycDocumentsQ = useQueries({ queries: kycDocumentsBuiltQ });

const kycDocuments = computed(() => allKycDocumentsQ.value?.flatMap((query) => query.data));

const addressProofIds = computed(
  () => kycDocuments.value?.filter(
    (kycDocument): kycDocument is AddressProof => !!kycDocument && isAddressProof(kycDocument),
  ).map((addressProof) => addressProof.id) ?? [],
);
const addressProofGeolocationsEnabled = computed(() => !!addressProofIds.value.length);

const memberLoanApplicationGeolocationsQ = useFetchGeolocations({
  geolocatableType: 'GroupMemberLoanApplication',
  geolocatableIds: groupMemberLoanApplicationIds,
});

const addressProofGeolocationsQ = useFetchGeolocations({
  geolocatableType: 'AddressProof',
  geolocatableIds: addressProofIds,
  enabled: addressProofGeolocationsEnabled,
});

const isLoading = computed(() => memberLoanApplicationGeolocationsQ.isLoading || addressProofGeolocationsQ.isLoading);

const geolocations = computed(() => [
  ...(memberLoanApplicationGeolocationsQ.data ?? []),
  ...(addressProofGeolocationsQ.data ?? []),
]);
const filteredGeolocations = computed(
  () => geolocations.value.filter((geolocation) => {
    let groupMemberLoanApplicationId: number | null = null;
    const isLabelSelected = filterValues[geolocation.label];

    if (geolocation.geolocatableType === 'GroupMemberLoanApplication') {
      groupMemberLoanApplicationId = geolocation.geolocatableId;
    } else if (geolocation.geolocatableType === 'AddressProof') {
      groupMemberLoanApplicationId = (geolocation.geolocatable as AddressProof).documentableId;
    }

    let isGroupMemberLoanApplicationSelected = false;
    if (groupMemberLoanApplicationId) {
      isGroupMemberLoanApplicationSelected = filterValues[groupMemberLoanApplicationId.toString()];
    }

    return isLabelSelected && isGroupMemberLoanApplicationSelected;
  }),
);

const mapColorsToGroupMemberLoanApplications = computed(() => (
  props.groupLoanApplication.memberLoanApplications.reduce(
    (map: Record<number, string>, memberLoanApplication, index) => {
      if (!map[memberLoanApplication.id]) {
        map[memberLoanApplication.id] = COLORS[index % COLORS.length];
      }

      return map;
    }, {})),
);

function getMemberDisplayName(groupMember: GroupMember) {
  const belongsToComittee = ['president', 'vice_president'].includes(groupMember.role);

  return `${groupMember.user.fullName} ${belongsToComittee ? `(${groupMember.humanRole})` : ''}`;
}

const legend = computed(() => props.groupLoanApplication.memberLoanApplications.map(
  (memberLoanApplication) => ({
    displayName: getMemberDisplayName(memberLoanApplication.groupMember),
    color: mapColorsToGroupMemberLoanApplications.value[memberLoanApplication.id],
    id: memberLoanApplication.id,
  }),
));

const mapCenter = computed(() => {
  if (!geolocations.value.length || !addressProofGeolocationsEnabled.value || !filteredGeolocations.value.length) {
    return { lat: 0, lng: 0 };
  }

  const center = filteredGeolocations.value.reduce(
    (acc, loc) => {
      acc.latitudeSum += parseFloat(loc.latitude);
      acc.longitudeSum += parseFloat(loc.longitude);
      acc.count += 1;

      return acc;
    },
    { latitudeSum: 0, longitudeSum: 0, count: 0 },
  );

  return {
    lat: center.latitudeSum / center.count,
    lng: center.longitudeSum / center.count,
  };
});

function getMarkerOptions(geolocation: Geolocation) {
  return {
    position: { lat: parseFloat(geolocation.latitude), lng: parseFloat(geolocation.longitude) },
  };
}

function getMarkerPinOptions(geolocation: Geolocation) {
  let groupMemberLoanApplicationId: number;
  if (geolocation.geolocatableType === 'GroupMemberLoanApplication') {
    groupMemberLoanApplicationId = geolocation.geolocatableId;
  } else if (geolocation.geolocatableType === 'AddressProof') {
    groupMemberLoanApplicationId = (geolocation.geolocatable as AddressProof).documentableId;
  }

  return {
    background: mapColorsToGroupMemberLoanApplications.value[groupMemberLoanApplicationId],
    borderColor: '#000000',
    glyphColor: GEOLOCATION_LABEL_GLYPH_COLOR[geolocation.label],
  };
}

const selectedGeolocation = ref<Geolocation | null>(null);
const selectedGroupMemberLoanApplication = computed(() => {
  if (!selectedGeolocation.value) {
    return null;
  }

  let groupMemberLoanApplicationId: number;
  if (selectedGeolocation.value.geolocatableType === 'GroupMemberLoanApplication') {
    groupMemberLoanApplicationId = selectedGeolocation.value.geolocatableId;
  } else if (selectedGeolocation.value.geolocatableType === 'AddressProof') {
    groupMemberLoanApplicationId = (selectedGeolocation.value.geolocatable as AddressProof).documentableId;
  }

  return props.groupLoanApplication.memberLoanApplications.find(
    (memberLoanApplication) => memberLoanApplication.id === groupMemberLoanApplicationId,
  );
});
</script>

<template>
  <div class="flex w-full justify-center">
    <div
      v-if="!!geolocations.length && !isLoading"
      class="flex h-[30rem] flex-col gap-y-5"
    >
      <div class="flex flex-wrap gap-x-5 gap-y-1 text-sm">
        <button
          v-for="legendItem in legend"
          :key="legendItem.color"
          class="flex items-center gap-x-2"
          :class="filterValues[legendItem.id.toString()] ? 'opacity-100' : 'opacity-50'"
          @click="filterValues[legendItem.id.toString()] = !filterValues[legendItem.id] ?? false"
        >
          <div
            class="size-3"
            :style="{ backgroundColor: legendItem.color }"
          />
          <p>{{ legendItem.displayName }}</p>
        </button>
      </div>
      <div className="flex flex-col gap-y-2">
        <p className="font-semibold">
          Centro del pin
        </p>
        <div class="flex flex-wrap gap-x-5 gap-y-1 text-sm">
          <button
            v-for="geolocationLabel in Object.keys(GEOLOCATION_LABEL_GLYPH_COLOR)"
            :key="geolocationLabel"
            class="flex items-center gap-x-2"
            :class="filterValues[geolocationLabel] ? 'opacity-100' : 'opacity-50'"
            @click="filterValues[geolocationLabel] = !filterValues[geolocationLabel] ?? false"
          >
            <div
              class="size-3"
              :style="{ backgroundColor: GEOLOCATION_LABEL_GLYPH_COLOR[geolocationLabel as Geolocation['label']] }"
            />
            <p>{{ t(`geolocation.label.${geolocationLabel}`) }}</p>
          </button>
        </div>
      </div>
      <base-google-map
        :center="mapCenter"
        :map-id="`GROUP_LOAN_APPLICATION_${groupLoanApplication.id}_GEOLOCATIONS`"
      >
        <AdvancedMarker
          v-for="geolocation in filteredGeolocations"
          :key="geolocation.id"
          :options="getMarkerOptions(geolocation)"
          :pin-options="getMarkerPinOptions(geolocation)"
          @click="selectedGeolocation = geolocation"
        />
        <InfoWindow
          v-if="selectedGeolocation && selectedGroupMemberLoanApplication"
          :options="{
            position: {
              lat: parseFloat(selectedGeolocation.latitude),
              lng: parseFloat(selectedGeolocation.longitude)
            },
            headerDisabled: true
          }"
        >
          <div className="flex flex-col gap-y-2">
            <div className="flex justify-between w-full">
              <p class="text-sm text-gray-500">
                {{ format(parseISO(selectedGeolocation.updatedAt), 'dd-MM-yyyy - HH:mm') }}
              </p>
              <XMarkIcon
                class="size-5 hover:cursor-pointer"
                @click="selectedGeolocation = null"
              />
            </div>
            <div className="flex flex-col">
              <div className="flex gap-x-1 items-center">
                <div
                  class="size-3"
                  :style="{
                    backgroundColor: mapColorsToGroupMemberLoanApplications[selectedGroupMemberLoanApplication.id]
                  }"
                />
                <p class="font-semibold">
                  {{ t(`geolocation.label.${selectedGeolocation.label}`) }}
                  - {{ getMemberDisplayName(selectedGroupMemberLoanApplication?.groupMember) }}
                </p>
              </div>
              <p>
                {{ selectedGroupMemberLoanApplication?.groupMember.humanRole }}
              </p>
            </div>
          </div>
        </InfoWindow>
      </base-google-map>
    </div>
    <base-spinner
      v-else-if="isLoading"
      :size="20"
      class="text-black"
    />
    <p
      v-else
      class="text-gray-500"
    >
      No hay ubicaciones para mostrar
    </p>
  </div>
</template>
