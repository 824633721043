<script setup lang="ts">
import { ClockIcon } from '@heroicons/vue/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid';
import { useQueryClient } from '@tanstack/vue-query';
import { formatDistanceToNow } from 'date-fns';
import { computed, reactive, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useRejectKycMutation } from '@/api/groupMemberLoanApplication/kycRejection/mutations';
import type { AasmStateEvent, KycDocument } from '@/api/kycDocument';
import { useUpdateKycDocument } from '@/api/kycDocument/mutations';
import { useFetchKycDocuments } from '@/api/kycDocument/queries';
import { groupLoanApplicationKey } from '@/api/queryKeys';
import BaseButton from '@/components/base-button.vue';
import BaseHeaderTable from '@/components/base-header-table.vue';
import BaseImageWithControls from '@/components/base-image-with-controls.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import BaseTabs from '@/components/base-tabs.vue';
import GroupLoanApplicationsMemberLoanKycAddressProof
  from '@/components/reviews/group-loan-applications-member-loan-kyc-address-proof.vue';
import UploadKycDocumentFileModal from '@/components/reviews/upload-kyc-document-file-modal.vue';
import useUIStore from '@/stores/ui';
import { isAddressProof } from '@/utils/kycDocumentsGuards';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication
}

const props = defineProps<Props>();

const { t } = useI18n();
const uiStore = useUIStore();

const documentableId = computed(() => props.groupMemberLoanApplication.id);

const kycDocumentsQ = useFetchKycDocuments({ documentableId, documentableType: 'GroupMemberLoanApplication' });

const kycDocuments = computed(() => kycDocumentsQ.data || []);

const allDocumentsReviewed = computed(() => (
  kycDocuments.value.every((kycDocument) => kycDocument.aasmState !== 'review_pending')
));
const someDocumentsRejected = computed(() => (
  kycDocuments.value.some((kycDocument) => kycDocument.aasmState === 'rejected')
));
const bannerText = computed(() => {
  if (!allDocumentsReviewed.value) return '';

  if (someDocumentsRejected.value) {
    return 'Todos los documentos han sido revisados, pero algunos han sido rechazados.';
  }

  return 'Todos los documentos de este miembro han sido aprobados.';
});
const groupMemberLoanApplicationId = computed(() => props.groupMemberLoanApplication.id);
const kycIsRejected = reactive<{ [key: number]: boolean }>({});
const rejectKycMutation = useRejectKycMutation({
  groupMemberLoanApplicationId,
  onSuccess: () => {
    uiStore.toast({
      message: 'KYC enviado a corregir',
      type: 'success',
      position: 'top',
    });
    kycIsRejected[props.groupMemberLoanApplication.id] = true;
  },
});

const queryClient = useQueryClient();

const updateDocumentableKycDocumentMutation = useUpdateKycDocument({
  documentableId,
  documentableType: 'GroupMemberLoanApplication',
  onSuccess() {
    queryClient.invalidateQueries({
      queryKey: groupLoanApplicationKey(toRef(props.groupMemberLoanApplication, 'groupLoanApplicationId')),
    });
  },
});

function reviewDocument(kycDocument: KycDocument, button: 'approve' | 'reject') {
  let aasmStateEvent : AasmStateEvent;

  if (button === 'approve') {
    aasmStateEvent = kycDocument.aasmState === 'approved' ? 'pass_to_review' : 'approve';
  } else {
    aasmStateEvent = kycDocument.aasmState === 'rejected' ? 'pass_to_review' : 'reject';
  }

  return updateDocumentableKycDocumentMutation.mutate({
    id: kycDocument.id,
    body: {
      aasmStateEvent,
      kycDocumentType: kycDocument.kind,
    },
  });
}

const tabs = computed(() => (
  kycDocuments.value.map((kycDocument) => (
    {
      title: t(`kycDocument.kinds.${kycDocument.kind}`),
      icon: (kycDocument.aasmState === 'approved' && CheckCircleIcon) ||
        (kycDocument.aasmState === 'rejected' && XCircleIcon) ||
      undefined,
      iconClass: (kycDocument.aasmState === 'rejected' && 'text-red-500') || undefined,
    }
  ))
));
const selectedTabIndex = ref(0);

const kycDocumentToAmend = ref<KycDocument | null>(null);

</script>

<template>
  <div class="flex w-full flex-col gap-y-4 px-2 py-4">
    <template v-if="groupMemberLoanApplication.kycSubmittedAt">
      <div class="flex items-center gap-x-1">
        <ClockIcon
          class="size-4 text-primary-600/70"
        />
        <p class="text-sm text-gray-700">
          KYC enviado
          {{ formatDistanceToNow(new Date(groupMemberLoanApplication.kycSubmittedAt), { addSuffix: true }) }}
        </p>
      </div>
      <base-header-table
        size="sm"
        title="Referencias"
        :headers="['Nombre', 'Teléfono', 'Relación']"
        :rows="groupMemberLoanApplication.references.map((reference) => [
          reference.name ?? '',
          reference.phoneNumber ?? '',
          reference.relationship ?? '',
        ])"
      />
      <base-spinner
        v-if="kycDocumentsQ.isPending"
        class="size-8 self-center"
      />
      <div
        v-else-if="allDocumentsReviewed"
        class="flex items-center justify-center gap-x-4 rounded-lg bg-primary-100 p-4 text-primary-700"
      >
        <p>{{ bannerText }}</p>
        <base-button
          v-if="someDocumentsRejected"
          text="Enviar a corregir"
          variant="primary"
          size="sm"
          :loading="rejectKycMutation.isPending"
          :disabled="kycIsRejected[props.groupMemberLoanApplication.id]"
          @click="rejectKycMutation.mutate()"
        />
      </div>
      <base-tabs
        :tabs="tabs"
        :selected-tab-index="selectedTabIndex"
      >
        <template
          v-for="(kycDocument, index) in kycDocuments"
          :key="kycDocument.kind"
          #[`tab-${index}`]
        >
          <div class="grid grid-cols-2 gap-x-4 overflow-hidden">
            <div class="flex flex-col gap-y-4 py-2">
              <base-image-with-controls
                v-if="kycDocument.fileUrl"
                :src="kycDocument.fileUrl"
                type="application/pdf"
                class="max-h-[60rem] w-full flex-1 rounded-lg object-contain"
              />
              <base-button
                v-if="kycDocument.aasmState === 'rejected'"
                variant="white"
                text="Corregir"
                @click="kycDocumentToAmend = kycDocument"
              />
              <upload-kyc-document-file-modal
                v-if="kycDocumentToAmend"
                :show="kycDocumentToAmend === kycDocument"
                :kyc-document="kycDocument"
                :group-loan-application-id="groupMemberLoanApplication.groupLoanApplicationId"
                :documentable-id="groupMemberLoanApplication.id"
                documentable-type="GroupMemberLoanApplication"
                @close="kycDocumentToAmend = null"
              />
            </div>
            <div class="flex flex-1 flex-col gap-y-4 rounded-lg border-2 p-4">
              <div class="flex flex-1 flex-col">
                <group-loan-applications-member-loan-kyc-address-proof
                  v-if="isAddressProof(kycDocument)"
                  :address-proof="kycDocument"
                />
                <template v-else>
                  <div class="flex flex-1 flex-col items-center">
                    <p>Verifica que el documento sea el correcto  </p>
                  </div>
                </template>
              </div>
              <div class="flex items-center gap-x-4">
                <base-button
                  class="flex-1"
                  :text="kycDocument.aasmState === 'rejected' ? 'Rechazado' : 'Rechazar'"
                  :variant="kycDocument.aasmState === 'rejected' ? 'red' : 'white'"
                  :loading="updateDocumentableKycDocumentMutation.isPending"
                  @click="reviewDocument(kycDocument, 'reject')"
                />
                <base-button
                  class="flex-1"
                  :text="kycDocument.aasmState === 'approved' ? 'Aprobado' : 'Aprobar'"
                  :variant="kycDocument.aasmState === 'approved' ? 'primary' : 'white'"
                  :loading="updateDocumentableKycDocumentMutation.isPending"
                  @click="reviewDocument(kycDocument, 'approve')"
                />
              </div>
            </div>
          </div>
        </template>
      </base-tabs>
    </template>
    <template v-else>
      <p class="py-16 text-center text-lg">
        Aún no se ha enviado el formulario de KYC
      </p>
    </template>
  </div>
</template>
