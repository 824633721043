<script setup lang="ts">
import { useForm, useFieldArray } from 'vee-validate';
import { computed, toRef } from 'vue';
import { array, mixed, object, string } from 'yup';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { KycUploadBody } from '@/api/groupMemberLoanApplication/support/';
import { useUploadKyc } from '@/api/groupMemberLoanApplication/support/mutations';
import BaseButton from '@/components/base-button.vue';
import VeeValidateFileInput from '@/components/vee-validate/vee-validate-file-input.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';
import VeeValidateWalichatPhoneInput from '@/components/vee-validate/vee-validate-walichat-phone-input.vue';
import useGroupMemberLoanApplicationKycDocuments from '@/composables/useGroupMemberLoanApplicationKycDocuments';
import useUiStore from '@/stores/ui';
import { textFieldSchema } from '@/utils/yupRules';

const MIN_REFERENCES = 2;

const uiStore = useUiStore();

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
}
const props = defineProps<Props>();

const { frontIdCard, backIdCard, selfieIdCard, addressProof } = useGroupMemberLoanApplicationKycDocuments({
  groupMemberLoanApplication: toRef(props, 'groupMemberLoanApplication'),
});

const validationSchema = object({
  bankAccountNumber: textFieldSchema,
  frontIdCard: mixed<File>().when('frontIdCardIsPresent', {
    is: () => !frontIdCard.value?.fileUrl,
    then: (schema) => schema.required('INE (Frontal) es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  backIdCard: mixed<File>().when('backIdCardIsPresent', {
    is: () => !backIdCard.value?.fileUrl,
    then: (schema) => schema.required('INE (Reverso) es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  selfieIdCard: mixed<File>().when('selfieIdCardIsPresent', {
    is: () => !selfieIdCard.value?.fileUrl,
    then: (schema) => schema.required('Selfie con INE es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  addressProof: mixed<File>().when('addressProofIsPresent', {
    is: () => !addressProof.value?.fileUrl,
    then: (schema) => schema.required('Comprobante de domicilio es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  references: array().of(
    object({
      name: string().required('El nombre es requerido'),
      phoneNumber: string().required('El número de teléfono es requerido').mobilePhone(['es-MX', 'es-CL']),
      relationship: string().required('La relación es requerida'),
    }),
  ).min(MIN_REFERENCES, 'At least one reference is required'),
});

const uploadKycMutations = useUploadKyc({
  groupMemberLoanApplicationId: toRef(props.groupMemberLoanApplication, 'id'),
  onSuccess: () => uiStore.toast({
    message: 'KYC enviado correctamente',
    type: 'success',
    position: 'bottom',
  }),
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al validar la CURP',
      type: 'error',
      position: 'bottom',
    });
  },
});

const initialReferences = computed(() => {
  const references = props.groupMemberLoanApplication.references;

  if (references.length >= MIN_REFERENCES) return references;

  if (references.length === 1) {
    return [
      ...references,
      { name: '', phoneNumber: '', relationship: '' },
    ];
  }

  return [
    { name: '', phoneNumber: '', relationship: '' },
    { name: '', phoneNumber: '', relationship: '' },
  ];
});

const { handleSubmit, values: formValues } = useForm<KycUploadBody>({
  validationSchema,
  initialValues: {
    bankAccountNumber: props.groupMemberLoanApplication.previousCycleBankAccountNumber || '',
    references: initialReferences.value,
  },
});

const { remove: removeReference, push } = useFieldArray('references');

function addReference() {
  push({ name: '', phoneNumber: '', relationship: '' });
}

const onSubmit = handleSubmit((values) => {
  uploadKycMutations.mutate(values);
});

</script>

<template>
  <form
    class="flex flex-col gap-y-6 rounded-lg bg-white p-6"
    @submit="onSubmit"
  >
    <vee-validate-input
      name="bankAccountNumber"
      label="CLABE"
      :custom-warning-message="
        !!props.groupMemberLoanApplication.previousCycleBankAccountNumber ?
          'Recuerda confirmar con la clienta que este dato sigue siendo correcto' :
          ''
      "
    />
    <vee-validate-file-input
      input-id="front-id"
      name="frontIdCard"
      label="INE (frontal)"
      extensions=".JPG, .JPEG, .PNG"
      :initial-file-url="frontIdCard?.fileUrl"
      initial-file-name="INE (frontal)"
    />
    <vee-validate-file-input
      input-id="back-id"
      name="backIdCard"
      label="INE (reverso)"
      extensions=".JPG, .JPEG, .PNG"
      :initial-file-url="backIdCard?.fileUrl"
      initial-file-name="INE (reverso)"
    />
    <vee-validate-file-input
      input-id="selfie-id"
      name="selfieIdCard"
      label="Selfie con INE"
      extensions=".JPG, .JPEG, .PNG"
      :initial-file-url="selfieIdCard?.fileUrl"
      initial-file-name="Selfie con INE"
    />
    <vee-validate-file-input
      input-id="address-proof"
      name="addressProof"
      label="Comprobante de domicilio"
      extensions=".JPG, .JPEG, .PNG, .PDF"
      :initial-file-url="addressProof?.fileUrl"
      initial-file-name="Comprobante de domicilio"
    />

    <div class="flex flex-col gap-y-4">
      <h3 class="text-lg">
        Referencias
      </h3>
      <p class="text-gray-600">
        Deben ser mínimo 2 referencias
      </p>
      <div
        v-for="(_, index) in formValues.references"
        :key="index"
        class="flex justify-between gap-x-4"
      >
        <vee-validate-input
          :name="`references[${index}].name`"
          label="Nombre"
          class="flex-1"
        />
        <vee-validate-walichat-phone-input
          :name="`references[${index}].phoneNumber`"
          label="Número de teléfono"
          class="flex-1"
        />
        <vee-validate-select
          :name="`references[${index}].relationship`"
          label="Relación"
          class="flex-1"
          :options="[
            { label: 'Familiar cercano (hermana/a,  madre, hija/o, padre) ', value: 'close_relative' },
            { label: 'Familiar segundo (prima/o, tía, abuela/o)', value: 'distant_relative' },
            { label: 'Familiar político (cuñada/o, suegra/o, yerna/o)', value: 'in_law_relative' },
            { label: 'Pareja (esposo/a, novio/a)', value: 'partner' },
            { label: 'Amistad', value: 'friend' },
            { label: 'Vecino/a', value: 'neighbor' },
            { label: 'Conocido/a', value: 'acquaintance' },
          ]"
        />
        <base-button
          class="h-12 self-center"
          type="button"
          text="Remove"
          :disabled="formValues.references.length <= 2"
          @click="removeReference(index)"
        />
      </div>
      <base-button
        type="button"
        variant="text"
        text="Add Reference"
        @click="addReference"
      />
    </div>

    <base-button
      type="submit"
      text="Enviar"
      :loading="uploadKycMutations.isPending"
    />
  </form>
</template>
