import { type UseQueryOptions } from '@tanstack/vue-query';
import { unref, toRef, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { get } from '@/api/groupLoanApplication/creditPolicyResult';
import type { GroupLoanApplicationCreditPolicyResult } from '@/api/groupLoanApplicationCreditPolicyResult';
import { groupLoanApplicationCreditPolicyResultKey as creditPolicyResultKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchCreditPolicyResultComposableArgs = {
  groupLoanApplicationId: MaybeRef<number>
} & UseQueryOptions<GroupLoanApplicationCreditPolicyResult, ApiError>;

function useFetchCreditPolicyResult(
  { groupLoanApplicationId, ...options }: FetchCreditPolicyResultComposableArgs,
) {
  return useReactiveQuery({
    queryKey: creditPolicyResultKey(toRef(groupLoanApplicationId)),
    queryFn: () => get(unref(groupLoanApplicationId)).then(
      response => response.data.groupLoanApplicationCreditPolicyResult,
    ),
    ...options,
  });
}

export { useFetchCreditPolicyResult };
