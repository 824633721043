<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed, ref, toRef } from 'vue';
import { string, object, boolean } from 'yup';

import type { Contract } from '@/api/contract';
import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useSignContractMutation } from '@/api/groupMemberLoanApplication/contractSignature/mutations';
import BaseButton from '@/components/base-button.vue';
import BaseModal from '@/components/base-modal.vue';
import BasePdfViewer from '@/components/base-pdf-viewer.vue';
import VeeValidateCheckbox from '@/components/vee-validate/vee-validate-checkbox.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import { normalize, toCurrency } from '@/utils/filters';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
  groupLoanApplication: GroupLoanApplication;
  contract: Contract;
}

const props = defineProps<Props>();

const fullName = computed(() => props.groupMemberLoanApplication.groupMember.user.fullName);
const normalizedFullName = computed(() => fullName.value && normalize(fullName.value));

const validationSchema = object().shape({
  contractTermsAccepted: boolean().oneOf([true], 'Debes aceptar los términos del contrato'),
  paymentAgreementAccepted: boolean().oneOf([true], 'Debes aceptar el acuerdo de pago'),
  fullName: string().required('Debes ingresar tu nombre completo'),
});

const { values, handleSubmit } = useForm({
  validationSchema,
  initialValues: {
    contractTermsAccepted: false,
    paymentAgreementAccepted: false,
    fullName: '',
  },
});
const normalizedInputName = computed(() => normalize(values.fullName ?? ''));
const isFullNameMatch = computed(() => normalizedFullName.value === normalizedInputName.value);
const showSignModal = ref(false);
const showContractModal = ref(false);
const isContractOnSigningStep = ['contract_signature_pending', 'contract_signed'].includes(
  props.groupMemberLoanApplication.aasmState,
);
const isContractSigned = ref(props.groupMemberLoanApplication.aasmState === 'contract_signed');

const signContractMutation = useSignContractMutation({
  groupMemberLoanApplicationId: toRef(props.groupMemberLoanApplication, 'id'),
  onSuccess: () => {
    isContractSigned.value = true;
  },
});

const onSubmit = handleSubmit(() => {
  signContractMutation.mutate();
});
</script>

<template>
  <div class="flex w-full flex-col items-center rounded-lg bg-white p-6">
    <form
      v-if="isContractOnSigningStep && !isContractSigned"
      class="flex flex-col gap-y-2"
    >
      <BaseModal
        :show="showContractModal"
        @close="showContractModal = false"
      >
        <template #body>
          <div
            v-if="props.contract.unsignedDocumentFileUrl"
            class="flex w-full flex-col gap-y-4"
          >
            <BasePdfViewer :url="props.contract.unsignedDocumentFileUrl" />
            <a
              :href="props.contract.unsignedDocumentFileUrl"
              target="_blank"
              class="text-primary-700"
            >
              Descargar
            </a>
          </div>
          <p v-else>
            El contrato no está disponible.
          </p>
        </template>
        <template #actions>
          <BaseButton
            type="button"
            class="w-full"
            text="Cerrar"
            @click="showContractModal = false"
          />
        </template>
      </BaseModal>
      <div class="flex items-center justify-between">
        <h3 class="font-semibold text-black">
          Detalles del contrato
        </h3>
        <BaseButton
          type="button"
          class="self-end"
          text="Ver contrato"
          @click="showContractModal = true"
        />
      </div>
      <ul class="text-sm">
        <li>💰 Monto otorgado: {{ toCurrency(props.groupMemberLoanApplication.acceptedAmount || 0) }}</li>
        <li>📌 Pago semanal: {{ toCurrency(props.groupMemberLoanApplication.repaymentAmount || 0) }}</li>
        <li>📤 Total a pagar: {{ toCurrency(props.groupMemberLoanApplication.totalRepaymentAmount || 0) }}</li>
        <li>💵 Costo por mil: {{ toCurrency(props.groupMemberLoanApplication.costPerThousand || 0) }}</li>
        <li>
          🚨 Multas grupales por atraso:
          {{ toCurrency(props.groupLoanApplication.delinquentFees.firstDay || 0) }} el primer día, luego
          {{ toCurrency(props.groupLoanApplication.delinquentFees.daily || 0) }} por cada día adicional de
          atraso.
        </li>
      </ul>
      <VeeValidateCheckbox
        name="contractTermsAccepted"
        label="
        He leído y Acepto todos los términos de la Carátula del Contrato de Adhesión de Crédito,
        el Acta de Instalación de Grupo Solidario,
        Reglamento Interno del Grupo Solidario,
        el Contrato de Adhesión de Crédito y el Pagaré.
      "
      />
      <VeeValidateCheckbox
        name="paymentAgreementAccepted"
        label="Me comprometo a pagar a tiempo, ser solidaria con mi grupo y ser aval de cada integrante."
      />
      <BaseModal
        :show="showSignModal"
        title="Firma el contrato escribiendo tu nombre completo"
        @close="showSignModal = false"
      >
        <template #title />
        <template #body>
          <div class="flex flex-col gap-y-4 text-left">
            <div class="flex flex-col gap-y-4 rounded-lg border border-gray-200 bg-gray-100 p-2 text-justify text-gray-700">
              <p>
                A través de mi nombre <span class="font-bold">{{ fullName }}</span>
                manifiesto que he leído y doy mi consentimiento
                absoluto a los términos del Contrato de Adhesión de Crédito de
                <span class="font-bold">{{ props.groupLoanApplication.group.name }}</span>,
                el Pagaré y sus anexos.
              </p>
              <p>
                Estoy consciente que de acuerdo con El Código Civil Federal, el
                consentimiento expresado por medios electrónicos, como la firma digital a través de
                MI NOMBRE COMPLETO y la verificación de mi identidad, es expreso, por lo que tiene
                la misma validez legal que una firma manuscrita y permite realizar acciones
                legales vinculantes en línea.
              </p>
            </div>
            <VeeValidateInput
              v-if="!!fullName"
              name="fullName"
              label="Escribe tu nombre completo:"
              :description="fullName"
            />
          </div>
        </template>
        <template #actions>
          <BaseButton
            class="w-full"
            type="submit"
            text="Firmar"
            :disabled="!isFullNameMatch"
            @click="onSubmit"
          />
        </template>
      </BaseModal>
      <BaseButton
        type="button"
        text="Firmar contrato"
        :disabled="!values.contractTermsAccepted || !values.paymentAgreementAccepted"
        @click="showSignModal = true"
      />
    </form>
    <template v-else-if="isContractOnSigningStep && isContractSigned">
      <p class="p-16 text-lg font-bold text-primary-700">
        El contrato ha sido firmado exitosamente.
      </p>
    </template>
    <template v-else>
      <p class="p-16 text-lg font-bold text-primary-700">
        No se puede firmar el contrato en este momento.
      </p>
    </template>
  </div>
</template>
